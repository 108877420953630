@use '../../../styles/variables' as *;

.delete-button {
  .button-text {
    font-family: 'Maven', sans-serif;
    font-size: 18px !important;
    font-weight: 500;
    line-height: 1.15;
    word-break: break-all;
  }
  width: 100%;
  margin-top: 5px;
  max-width: unset;
  padding: 0 20px;
  color: white;
}
.header-ticket-row {
  .ticket-heading-title {
    font-size: 22px;
    color: #ffd500;
    margin-left: 15px;
    margin-bottom: 40px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.error-message {
  color: $color-damage-red;
  font-size: 16px;
}
.confirm-delete-modal {
  background-color: rgba(0, 0, 0, 0.35);

  .confirm-delete-modal-content {
    width: 400px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    .confirm-modal-title {
      color: #00587f;
      font-size: 16px;
      font-weight: 300;
    }
  }
}

.ticket-details {
  .details-container {
    min-height: 100vh;

    .ticket-details-overview {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 0;
      padding-left: 25px;
      padding-bottom: 25px;

      .action-buttons {
        max-width: 370px;
      }
    }
  }

  .add-driver-container {
    top: 130px;
    left: 100px;
  }

  .file-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding-top: 10px;
  }
}
