.reset-password {
  padding: 100px;
  max-width: 1400px;

  .forgot-eye {
    position: relative;

    img {
      position: absolute;
      bottom: 14px;
      right: 11px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .reset-password-title {
    font-size: 45px;
    line-height: 1.6;
    color: #00273c;
    font-weight: 500;
    text-align: start;
  }

  .reset-password-bottom {
    padding-top: 20px
  }

  .input.full {
    margin-right: 0;
  }
}

@media only screen and (max-width: 560px) {
  .reset-password {
    padding: 50px 10px 10px 10px;

    .reset-password-title {
      font-size: 30px;
    }
  }
}