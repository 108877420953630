@use '../../../styles/variables' as *;

.chart-container {
  width: 100%;
  height: 30vw;
  max-width: 100vw;
  min-height: 30vw;
  border-radius: 3px;
  position: relative;
}

.filter-buttons {
  position: absolute;
  background-color: $color-white;
  border-radius: 4px;
  top: 60px;
  right: 50px;
}

@media (max-width: 1200px) {
  .chart-container {
    height: 40vw;
  }
}
