.vehicles-container {

    .vehicle-row {
      text-align: center;
      justify-content: space-between;
      flex-direction: column;

      .image-popup {
        max-width: 903px;
        margin: 40px auto 0;

        .pdf-frame {
          width: 810px;
          height: 800px;
        }
      }

      .no-pdf{
        background-color: #E84D0E;
        font-size: 16px;
        color: white;
        padding: 8px;
        border-radius: 1px;
        line-height: 20px;
      }

      .download {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFD500;
        box-shadow: 0 3px 6px #00000029;
        border-radius: 10px;
        width: 360px;
        color: #00273C;
        text-align: center;
        font-weight: 500;
        padding: 0 30px;
        height: 50px;
        margin-top: 30px;
        font-size: 14px ;
        font-weight: 500;
        margin-left: auto;
        margin-right: auto;

        &:hover {
          cursor: pointer;
          background-color: #ffd83d;
          border-color: transparent;
          color: rgba(0, 0, 0, 0.7);
        }
        span {
          flex-grow: 1;
        }
        svg {
          width: 24px;
          height: auto;
        }
      }
  }
}
#no-pdf {
  margin-top: 20px;
}
