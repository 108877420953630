.invoice-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 40px 80px 20px 80px;

  .invoice-subtitle{
    font-size: 23px;
    font-weight: 500;
    color: #00587f;
  }

  .additional-services-subtitle {
    margin-top: 40px
  }

  .individual-invoices {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 25px;

    .single {
      width: 80%;
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      max-width: 400px;

      :first-child{
        font-size: 16px;
        color: #00587f;
      }

      :last-child {
        font-size: 16px;
      }
    }

    .sub-section {
      margin-top: 30px;
    }
  }
  .contracts-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 25px;

    .single-contract {
      display: flex;
      flex-direction: row;
      margin-top: 8px;
      margin-bottom: 10px;
      width: 100%;

      .licence {
        width: 150px;
        color: #00587f;
      }
      .positions {
        width: 100%;

        .position {
          justify-content: space-between;
          display: flex;
          margin-bottom: 8px;

          span {
            font-size: 16px;
          }

          .sum {
            width: 150px;
            display: flex;
            justify-content: flex-end;
          }

          .title{
            width: 100%;
            color: #00587f;
          }
        }
      }
    }
  }

    .bold {
      font-weight: 500;
    }

  .inv-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
