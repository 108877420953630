.search_list_filter {
  margin-top: 20px;
  margin-left: 45px;
  position: relative;

  .search-container {
    display: inline-block;
    position: relative;

    .settings-icon {
      z-index: 5;
      right: 16px;
      cursor: pointer;
    }
  }
}
