.additional-list-item {
  padding-top: 20px;
}

.additional-list-item .damage-images{
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.additional-list-item .damage-images .additional-info-img {
  padding-left: 10px;
  cursor: pointer;
  width: 130px;
  height: 60px;
  margin-bottom: 10px;
}


.additional-list-item .damage-images .additional-info-img  img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
