.car-icon {
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.car-icon img {
  max-width: 100%;
  max-height: 100%;
}