@use '../../styles/variables' as *;

.privacy-policy-public {
  overflow: auto;
  max-height: 100vh;
  padding: 40px 20% 60px 20%;

  .privacy-text {
    text-align: justify;
    padding: 35px;

    a {
      font-size: 20px;
      line-height: 1.15;
      color: #00587f;
      padding: 0 6px;

      &:hover {
        text-decoration: underline;
        text-decoration-color: #2d7695;
        cursor: pointer;
        color: #00587f;
      }
    }

    .footer {
      border-top: 2px solid black;
      margin-top: 40px;
    }

    h2,
    p,
    li {
      font-size: 20px;
      line-height: 1.15;
    }

    h2 {
      margin-top: 40px;
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: 500;
      font-style: italic;

      &.blue {
        margin-top: 20px;
        font-size: 20px;
        font-style: normal;
      }
    }

    p {
      margin: 14px 0;
    }

    ul {
      list-style-type: disc;
      padding-left: 3ch;
    }
  }

  .right-title {
    font-size: 45px;
    line-height: 1.6;
    color: $color-blue-dark;
    font-weight: 500;
    text-align: center;
  }
}

@media (max-width: 1700px) {
  .privacy-policy-public {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media (max-width: 768px) {
  .privacy-policy-public {
    padding-left: 5%;
    padding-right: 5%;

    .right-title {
      font-size: 35px;
    }
  }
}

@media (max-width: 480px) {
  .privacy-policy-public {
    padding-left: 2%;
    padding-right: 2%;

    .right-title {
      font-size: 28px;
    }
  }
}
