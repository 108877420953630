.search-wrapper {
  position: relative;
  z-index: 100;
}

.select-search {
  position: relative;
  width: 100%;
  max-width: 360px;
}

.search-input-field {
  width: 100%;
  padding: 10px 0 12px 20px;
  border-radius: 23px !important;
  background-color: rgb(178, 204, 216);
  border: 1px solid rgb(178, 204, 216);
  font-size: 14px;
}

.search-wrapper .arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  right: 5px;
  color: #ffd500;
}

.search-wrapper .arrow.rotate {
  transform: translate(0%, -50%) rotate(180deg);
}

.dropdown-list > .inner-dd-list {
  position: absolute;
  top: 19px;
  left: 0;
  z-index: 1;
  width: 100%;
  max-width: 360px;
  max-height: 200px;
  overflow-y: auto;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background: #b2ccd8;
  padding: 25px 12px 5px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.select-search .dropdown-list .select-search-item {
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: center;
}

.list-loading {
  padding: 5px 2px;
  display: flex;
  max-width: 320px;
  justify-content: center;
  align-items: center;
  margin-top: -2px;
  margin-left: 13px;
  background-color: transparent;
  margin-right: 13px;
}

.list-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark {
  display: inline-block;
  position: absolute;
  right: 35px;
  top: 50%;
  cursor: pointer;
  color: #91c11e;
  transform: translateY(-50%);
}
