.checklist-item {
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.item-type {
  color: #4a4a4a;
  font-size: 13px;
}

.item-icon {
  text-align: center;
}

.fuel-levels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.item-icon {
  svg {
    fill: #707070;
    max-width: 20px;
    height: 21px;
    margin-top: -2px;
    padding-right: 3px;
  }
  svg.red {
    fill: red;
  }

  svg.green {
    fill: green;
  }
}

.ml-2 {
  margin-left: 5px;
}
