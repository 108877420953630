.damage-item {
  border: 1px solid #eee;
  padding: 5px 10px;
  margin-bottom: 5px;

  .text-blue {
    color: #6298b0;
  }
}

.bold {
  font-weight: 500;
}

.row {
  display: flex;
  justify-content: space-between;
}

.damage-loader {
  display: flex;
  justify-content: center;
}

.split {
  justify-content: space-between !important;
}

.arrow-icon {
  color: #f8eec2;
}

.arrow-icon.up {
  transform: rotate(180deg);
}
