@use '../../styles/variables' as *;

.service-info {
  min-height: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  .abc {
    margin-top: 7px;
  }

  .garage_history {
    padding-bottom: 4px;

    &_row {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 343px;
      padding: 0 12px 0 24px;
      margin-top: 10px;
    }

    &_type {
      width: 100px;
      text-align: left;
    }
  }

  .input {
    flex: 0 0;
    margin-bottom: 20px;
    input {
      padding: 0 16px;
      height: 36px;
      border-radius: 100px;
      background-color: rgba(255, 255, 255, 0.05);
      color: $color-white;
    }
  }
  .btn-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .location-text {
    a:first-of-type {
      margin-bottom: 3px;
      margin-right: 3px;
    }
  }

  .location-text .button {
    svg {
      position: absolute !important;
      left: 22px;
      color: $color-blue-dark;
    }

    .button-text {
      padding-left: 25px;
      text-align: left;
      color: $color-blue-dark;
      font-size: 20px;
    }

    .spacer {
      max-width: 3px;
      width: 3px;
      height: 45px;
      background: $color-blue-middle;
      right: 43px;
      position: absolute;
    }
  }

  .icon-row {
    display: flex;
    flex-direction: row;
  }

  .btn-icon {
    background: $color-yellow;
    height: 45px;
    line-height: 45px;
    border-radius: 0 10px 10px 0;
    display: block;
    width: 100%;
    text-align: center;
    position: relative;

    svg {
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translate(-18px, -50%);
      color: $color-black;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .btn-icon.down svg {
    transform: rotate(180deg);
    left: 13px;
    top: 11px;
  }

  .location-text > a:first-of-type button {
    border-radius: 10px 10px 0 0 !important;
    // display: none;
  }
  .location-text p a:last-of-type button {
    border-radius: 0 0 0 10px !important;
  }

  .location-text > a {
    position: relative;
    > svg {
      position: absolute;
      right: 10px;
      z-index: 9;
      top: 11px;
      color: $color-blue-dark;
      transform: rotate(180deg);
    }
  }

  .service-row {
    margin-right: -8px;
    margin-top: 5px;

    .btn-back {
      color: $color-yellow;
      margin-bottom: 8px;
      position: relative;
      padding-left: 48px;
      span {
        position: absolute;
        left: 24px;
        top: -1px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .icon-set {
    padding: 0 28px;
    margin-bottom: 20px;
    min-height: 36px;
    height: 36px;
    justify-content: space-between;

    svg {
      width: 28px;
      height: auto;
    }
  }

  .service-name {
    color: $color-white;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    margin-bottom: 16px;
    margin-top: 8px;
    padding-right: 16px;
  }
  .services {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    max-width: 320px;
    margin: 0 auto;
    margin-right: 5px;

    .service {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 90px;
      width: 90px;
      border-radius: 5px;
      background-color: $color-white;
      text-align: center;
      margin: 0 !important;
      padding: 0 !important;
      border: none;

      .service-icon {
        width: 40px;
        height: 40px;
        margin-top: 12px;
        background: $color-white;
        position: relative;

        svg {
          color: $color-black;
          position: absolute;
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
        }
      }

      .service-type {
        font-size: 16px;
        line-height: 18px;
        color: $color-black-gray;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 5px;
        max-width: 76px;
      }
    }
  }

  .service-location {
    display: flex;
    margin-left: 4px;
    margin-bottom: 16px;
    margin-top: 18px;
    .location-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 60px;

      svg {
        fill: $color-yellow;
      }
    }
    .location-text {
      color: $color-white;
      font-size: 18px;
      line-height: 1.17;
    }
  }

  .service-available {
    text-align: center;
    font-size: 16px;
    color: $color-white;
    margin-bottom: 10px;
    margin-top: -6px;
  }

  .service-history {
    color: $color-white;
    font-size: 18px;
    text-align: center;
    line-height: 1.17;
    margin-bottom: 16px;
  }

  .spacer {
    flex: 1 1 100%;
  }

  .button {
    height: 45px;
    width: 283px;
    border-radius: 10px 0 0 10px;
    span {
      font-size: 20px;
    }
  }

  a {
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
  }

  .location-text .icon-row.border a button {
    border-radius: 10px 0 0 10px !important;
    // border-radius: 0 0 0 10px !important;
  }

  .tooltip-link {
    display: table-cell;
    height: 60px;
    width: 80px;
    background: #fff;
    border: 5px solid white;
  }
}

.btn-back {
  color: $color-yellow;
  margin-bottom: 8px;
  position: relative;
  padding-left: 48px;
  span {
    position: absolute;
    left: 24px;
    top: -1px;
  }
  &:hover {
    cursor: pointer;
  }
}

.garage-row {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between !important;
  margin-bottom: 14px;

  p {
    padding: 0 10px;
    font-size: 18px;
  }
  span {
    padding-right: 2px;
    &::after {
      content: ', ';
    }
    &:last-child::after {
      content: '';
    }
  }
}
