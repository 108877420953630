.user-avatar {
  .icon-container {
    right: -4px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: #c9c9c9;
    padding: 1px 2px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 12px;
      padding-bottom: 0;
    }
  }
}
