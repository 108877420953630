@use '../../../styles/variables' as *;

.add-damage-container {
  height: 100vh;
  overflow-y: auto;
  position: absolute;
  display: flex;
  flex-grow: 0;
  background: $color-white-light !important;
  margin-left: 55px;

  &::-webkit-scrollbar {
    display: none;
  }

  &.step-1 {
    width: 25vw;
  }

  &.step-2 {
    width: 50vw;
  }

  &.step-3 {
    width: 75vw;
  }

  &.step-4 {
    width: calc(100% - 55px);
  }

  .arrow-buttons {
    position: fixed;
    bottom: 20px;
    width: 20vw;
    z-index: 6;

    &.step-1 {
      left: calc(25vw * 0 + 55px + 2.5vw);
      > button {
        max-width: 400px;
      }
    }

    &.step-2 {
      left: calc(25vw * 1 + 55px + 2.5vw);
    }

    &.step-3 {
      left: calc(25vw * 2 + 55px + 2.5vw);
    }

    &.step-4 {
      right: 10px;
    }
  }

  .cancel-add-damage-button {
    position: absolute;
    z-index: 99;
    width: 60px;
    height: 30px;
    top: 10px;
    right: -10px;

    button {
      background-color: transparent;
      border: none;
      border-radius: 50px;
      &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
