.vehicle-expand-arrow {
  background-color: #00587f;
  border-radius: 50%;
  padding: 5px 6px 5px 6px;
  margin-right: 10px;
  height: 24px;
  width: 24px;

  .svg {
    padding: 10px;
  }
}
