@use '../../../../styles/variables' as *;

.react-datepicker-popper[data-placement^='bottom'] {
  z-index: 599;
  left: 0 !important;
}

.main-form-container {
  background-color: $color-white-light;
  padding: 20px;
  width: 25vw;

  .license-popup {
    margin-top: 20px;
    position: relative;
    width: auto;

    .close {
      position: relative;
    }
  }

  .error-message-loc {
    margin: 0;
    color: red;
  }

  .step-one {
    margin-top: 40px;
    .timepicker {
      margin-top: 29px;
      height: 34px !important;
      background-color: $color-blue-white !important;
      border: 1px solid #b2ccd8 !important;

      div {
        input {
          height: 0.05rem;
          font-family: 'Maven', sans-serif;
          font-size: 16px;
        }

        fieldset {
          border-color: #b2ccd8 !important;
        }
      }
    }

    .calendar {
      background-color: $color-blue-white !important;
      border-radius: 5px;
      height: 34px;
      padding: 5px 8px;

      .calendar-icon {
        margin-top: 5px;
      }

      svg {
        cursor: pointer;
      }
    }

    .picker-label {
      margin-left: 10px;
      font-size: 15px;
      line-height: 1.21;
      margin-bottom: 10px;
      padding: 0;
    }

    .input {
      margin-right: 0;
    }

    .input > input,
    .input textarea {
      font-family: 'Maven', sans-serif;
      font-size: 16px;
    }

    .select-search {
      max-width: 95%;
    }

    .dropdown-list > .inner-dd-list {
      max-width: 100%;
    }
  }
}
