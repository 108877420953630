.swiper {
  width: 100%;
  height: 80vh;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.car-check-image {
  max-width: 100%;
  height: auto;
  display: block;
}

.loader-image {
  max-width: 100%;
  height: auto;
  display: block;
}
