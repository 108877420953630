.inspection-container {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  font-size: 15px;

  .inspection-left {
    display: flex;
    flex-direction: column;
  }
  .inspection-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .inspection-icon {
    display: flex;
    align-items: flex-start;
    svg {
      height: 18px;
      width: 18px;
    }
  }
}
