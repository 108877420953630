.employee-time-tracking-list {
  max-width: 446px;

  .invoice-card .card .right-section {
    width: 70%;
  }
}

#tracking-list {
  .invoice-card .card .right-section {
    width: 70%;
  }
}
