.trip-type {
  width: 60px;
  height: 60px;
  padding: 12px;
  cursor: pointer;
  border: none;
  background-color: #ffe97e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-left: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.trip-type.active {
  background-color: #ffd52a;
}
