@use '../../../styles/variables' as *;

.license-plate {
  display: flex;
  align-items: center;
  cursor: default;
  overflow: hidden;
  line-height: 25px;
  user-select: none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  width: 115px;

  &.clickable {
    &:hover {
      cursor: pointer;
    }
  }

  .country-container {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 0 5px;
    background-color: $color-blue-middle;
    color: $color-white-light;
    font-size: 16px;
    font-weight: 500;
  }

  .license-container {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 100%;
    padding: 0 10px;
    white-space: nowrap;
    color: $color-black-light;
    background-color: $color-white;
  }
  .default-license {
    padding-left: 20px;
    min-width: 55px;
  }
}
