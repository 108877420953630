.car-check-img-wrapper {
  display: flex;
  justify-content: left;
}

.car-check-image:hover {
  cursor: pointer;
}

.car-check-image {
  width: 60px;
}
