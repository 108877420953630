.license-popup {
  position: absolute;
  max-height: 350px;
  width: 360px;
  top: 0;
  right: 0;
  display: flex;
  background: #f5f5f5;
  border-radius: 20px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  user-select: none;
}

.license-popup .license-img {
  width: 100%;
  height: 100%;
  background-color: #fff;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
}

.license-popup .close {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}
.close:after,
.close:before {
  content: '';
  height: 20px;
  width: 20px;
  border-top: 3px solid #4a4a4a;
  position: absolute;
  top: 5px;
  right: -14px;
  transform: rotate(-45deg);
}
.close:before {
  right: 0;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.close:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
  opacity: 0.3;
}

.license-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.license-img .loader-image {
  width: 50px;
  min-height: 150px;
}

/* Popup arrow */
.license-popup .license-img .license-img::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.license-popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
