@use '../../../styles/variables' as *;

.station {
  display: flex;
  margin-bottom: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  border-right: 5px solid $color-yellow !important;
  padding-top: 4px;
  padding-bottom: 4px;
  background: $color-blue-dark;
  margin-left: -4px;
  margin-right: -3px;
  height: 54px;
  min-height: 54px;
  &:hover {
    cursor: pointer;
  }

  .price {
    display: flex;
    align-items: center;
    margin: 1px 8px 1px 4px;
    width: 60px;
    text-align: center;
    background: linear-gradient(to right, #363636, #010a0e);
    justify-content: center;
    font-family: 'DS', sans-serif;

    p {
      position: relative;
      margin-left: -5px;
      font-size: 25px;
      span {
        right: -4px;
        top: 1px;
        position: absolute;
        font-size: 10px;
        color: $color-white-grey;
      }
    }
  }

  .street {
    width: calc(100% - 100px);
    padding-right: 4px;
    font-size: 14px;
    line-height: 17px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: lowercase;
    &::first-letter {
      text-transform: capitalize !important;
    }

    .address {
      text-transform: capitalize;
    }

    &_st {
      display: flex;
      flex-direction: row;
      justify-content: left;
      flex-wrap: nowrap;
      & > span:first-of-type {
        text-transform: lowercase;
      }

      span:first-of-type {
        text-transform: uppercase;
      }
    }
  }

  .row {
    width: calc(100% - 50px);
  }

  .data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: 100%;

    .brand {
      width: 100px;
      padding-left: 4px;
      padding-right: 6px;
      font-size: 16px;
      line-height: 18px;
      display: flex;
      align-items: center;
      overflow-wrap: anywhere;
      word-break: break-word;
    }
  }
}
