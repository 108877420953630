.skeleton {
  width: 25px;
  height: 25px;
}

.skeleton img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
