.card .card-inner .top-section .right-section {
  width: 60%;

  > * {
    margin: 0;
  }

  .rentalTax {
    font-size: 10px;
  }

  .rentalSum {
    margin-top: 4px;
  }
}

.list-container {
  .rental_border_red {
    border-right: 5px solid #e84d0e;
  }

  .rental_border_grey {
    border-right: 5px solid #c9c9c9;
  }

  .rental_border_yellow {
    border-right: 5px solid #ffd500;
  }

  .rental_border_green {
    border-right: 5px solid #95c11e;
  }
}

#daily-rent-list {
  .invoice-card .card .card-inner .top-section .left-section {
    width: 90%;
  }
}
