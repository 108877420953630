.map-overlay {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #dadada;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-overlay .map-text {
  font-size: 24px;
  color: #fff;
}
