@use '../../styles/variables' as *;

.task-selector {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;

  .task-box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: 96px;
    border-radius: 4px;
    background: rgba($color-yellow, 0.5);
    margin-right: 8px;
    color: $color-blue-middle;
    font-size: 16px;
    justify-content: center;
    flex: 1;
    padding: 8px 12px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      cursor: pointer;
    }

    &.active {
      background-color: $color-yellow;
    }

    span {
      font-size: 18px;
      line-height: 1.17;
      justify-content: center;
      align-self: flex-end;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
