@use '../../styles/variables' as *;

.pop-up {
  padding: 40px 0 0 0;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  background: #f8f8f8;
  box-shadow: 0 3px 6px #00000029;
  z-index: 99;
  border-radius: 5px;
  max-width: 400px;
  color: #666666;
  line-height: 20px;

  &_close {
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .input {
    margin-top: 0 !important;
  }

  input {
    width: calc(100% - 80px);
    margin: 0 auto;
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
  }

  &_no-valid {
    max-width: 320px;
    margin: 0 auto;
    position: absolute;
    bottom: 80px;
    left: 40px;
    color: $color-damage-red;
    font-size: 13px;
  }

  &_success {
    text-align: center;
    font-weight: 500;
    color: #0a2536;
    font-size: 22px;
    font-family: 'Maven', sans-serif;
    width: 100%;
    border-top: 1px solid #dbdbdb;
    height: 50px;
    line-height: 50px;
    margin-top: 30px;
    border-radius: 0 0 5px 5px;
    &:hover {
      cursor: pointer;
      background: #eeeeee;
    }
  }

  &_row-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 30px;
    margin-top: 30px;

    .button {
      width: 308px;
      text-align: center;
      height: 50px;
      border-radius: 5px;
      text-transform: capitalize;
      position: relative;

      svg {
        position: absolute;
        left: 15px;
      }

      &.green {
        font-weight: 500;
        background: linear-gradient(to right, #95c11e 0%, #6fc11e 100%, #6fc11e 100%);
        color: #fff;

        &:hover {
          font-weight: 500;
          background: linear-gradient(to right, #95c11e 0%, #6fc11e 100%, #6fc11e 100%);
          cursor: pointer;
          border-color: transparent;
          opacity: 0.9;
        }
      }
      &.red {
        background: linear-gradient(to right, #e8490f, #e82a0e);
        &:hover {
          background: linear-gradient(to right, #e8490f, #e82a0e);
          cursor: pointer;
          border-color: transparent;
          opacity: 0.9;
        }
      }
      &.yellow {
        background-color: #ffd500;
        &:hover {
          background-color: #ffd500;
          cursor: pointer;
          border-color: transparent;
          opacity: 0.9;
        }
      }
      box-shadow: 0px 3px 6px #00000029;
    }
  }

  .button {
    border: unset;
    color: #0a2536;
    font-size: 22px;
    font-family: 'Maven', sans-serif;
    &:hover {
      cursor: pointer;
      background: #eeeeee;
    }
  }

  &_title {
    text-align: center;
    color: #00587f;
    margin-bottom: 14px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 24px;
    font-weight: 500;
  }

  &_description {
    font-size: 16px;
    padding-left: 40px;
    padding-right: 40px;
    color: #0a2536;
  }

  &_description {
    text-align: center;
  }

  &.popup-over:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #0000003d;
    z-index: 90;
    visibility: visible;
  }
}
