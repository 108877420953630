.additional-damage-upload .title {
  font-size: 18px;
  color: #00587f;
  padding-bottom: 15px;
}

.damage-image-list {
  flex: 1 1;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.damage-image-list .image-container {
  width: 110px;
  height: 70px;
  position: relative;
  margin: 10px;
  border: 1px solid gray;
  border-radius: 10px;
}

.damage-image-list .image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.damage-image-list .image-container .img-remove {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -5px;
  right: -5px;
  z-index: 5;
  border-radius: 50%;
  background-color: #e74c3c;
  padding: 2px;
  color: #fff;
}

.additional-damage-upload .Upload {
  padding-top: 10px;
}


.additional-damage-upload .error-message {
  margin: 0;
  color: red;
}
