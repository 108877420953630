@use '../../../../styles/variables' as *;

.create-appointment {
  display: flex;
  flex-direction: row;

  .need-done {
    .tire-group {
      display: flex;
      margin-bottom: auto;
      margin-left: 15px;
      margin-top: 40px;

      .tire-input {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .wheels-image {
        margin-left: -20px;
        margin-right: -20px;
      }
    }

    .message_tire {
      margin-top: 40px;

      p {
        font-size: 16px;
      }
    }

    .tire-error {
      margin-top: 20px;
      margin-left: 8px;
      margin-right: 8px;
      .error-message {
        color: red;
      }
    }

    .arrow_button {
      margin-bottom: 14px;
    }

    .arrow_button {
      margin-bottom: 15px;
    }
  }
}
