.daily-rent-report-container {
  padding: 25px 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.report-download-container {
  display: flex;
  justify-content: center;
}