@use '../../../styles/variables' as *;

.header-ticket-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .contract-details-heading-title {
    font-size: 22px;
    color: $color-yellow;
    margin-left: 15px;
    margin-bottom: 40px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  svg.contract-document-icon {
    padding: 10px;
  }
}

.detailsRow {
  margin-top: 10px;
}
.left-column > .detailsRow > .label {
  min-width: 200px;
}
.right-column > .detailsRow {
  justify-content: space-between;
  .text {
    min-width: 120px;
    text-align: right;
  }
}

.details-container {
  .contract-details {
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    .left {
      flex-basis: 40;
    }
  }
}

.details-separator-line {
  height: 2px;
  width: 100%;
  color: $color-blue-middle;
  margin: 10px 0;
}

.download-button-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
