@use '../../../../styles/variables' as *;

.data-form {
  flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex: 1;
  margin-top: 20px;

  .form-line {
    padding-top: 5px;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }

    .form-name {
      flex: 0 0 160px;
      color: $color-blue-middle;
    }

    .form-value {
      margin-left: 8px;
      word-break: break-all;
    }
  }

  .form-line_overdue {
    color: #e8b50e;
  }

  .form-line_open {
    color: #fa0414;
  }

  .form-line_approved {
    color: #95c11e;
  }

  .form-line_performed {
    color: #e8b50e;
  }
}
