.vehicle-license-plate {
  background: #fff;
  display: inline-flex;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 10px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);

  fieldset {
    border: none;
  }

  .license-container {
    display: flex;
    align-items: center;
  }

  .country-container {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 6px 10px;
    background-color: #00587f;
    color: #eef5f8;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .separator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }
}
