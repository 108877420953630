@use '../../../styles/variables' as *;

.edit-tag-container {
  z-index: 1;
  max-width: 400px;
  height: 850px;
  background: white;
  margin-top: 20px;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.input-label {
  color: #00587f;
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 10px;
}
