.button-list {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;

  .button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    &.column {
      flex-direction: column;

      .button {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.buttonColumn {
  flex-direction: column;
}
