.car-check-card {
  background-color: #fff;
  margin-bottom: 8px;
  border-radius: 7px;
}

.full-width {
  width: 100%;
}

.p-10 {
  padding: 10px;
}

.pl-5 {
  padding-left: 5px;
}

.py-3 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.row {
  display: flex;
  justify-content: normal;
}

.bold {
  font-weight: 700;
}

.column {
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.align-items-center {
  align-items: center;
}
