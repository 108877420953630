.inputGroup {
  display: flex;
  flex-direction: column;
  height: 25%;
  width: 80px;

  .input-tire {
    background-color: #bad4dc;
    width: 45px;
    border-radius: 5px;
  }

  .toggler .react-toggle-thumb {
    display: inherit;
    width: 10px;
    height: 10px;
    top: -2px;
    left: -1px;
  }

  .toggler .react-toggle-track {
    width: 20px;
    height: 6px;
  }

  .toggler {
    margin-top: 6px;
    margin-bottom: 8px;

    .label {
      display: block;
    }
  }

  .toggler .react-toggle--checked .react-toggle-thumb {
    left: 14px;
    border-color: #ffd500;
    background-color: #ffd500;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.41);
  }

  .toggler .react-toggle--checked .react-toggle-track {
    background-color: #ffd500;
  }

  .toggler .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    border-color: #ffd500;
  }

  .toggler .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #ffd500 !important;
  }

  .setting .toggler .react-toggle--checked .react-toggle-thumb {
    left: 16px;
    border-color: #ffd500;
  }

  &:hover {
    cursor: pointer;
  }
}
