@use '../../../styles/variables' as *;

.input {
  color: $color-input-text;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .input-label {
    font-size: 14px;
    line-height: 1.21;
    margin-bottom: 10px;
  }

  > p {
    font-size: 14px;
    color: $color-blue-middle;
  }

  > textarea {
    resize: none;
    flex: 1;
    box-sizing: border-box;
  }

  > input,
  textarea {
    width: 100%;
    background-color: $color-input-background;
    border: none;
    font-size: 18px;
    font-family: 'Maven', sans-serif;
    padding: 10px;
    border-radius: 5px;
    box-sizing: border-box;
  }

  &.one-fifth {
    flex: 0 0 calc(20% - 4px);
    margin-right: 8px;
  }

  &.four-fifth {
    flex: 0 0 calc(80% - 4px);
    margin-right: 8px;
  }

  &.quarter {
    flex: 1 1 25%;
    margin-right: 8px;
  }

  &.one-third {
    flex: 1 1 33%;
    margin-right: 8px;
  }

  &.half {
    flex-basis: 50%;
    margin-right: 8px;
  }

  &.two-thirds  {
    flex: 1 1 66;
    margin-right: 8px;
  }

  &.three-quarters {
    flex: 1 1 75%;
    margin-right: 8px;
  }

  &.full {
    flex: 1;
    margin-right: 8px;
  }

  &.margin-left {
    margin-left: 40px;
  }

  .select-box {
    .select__control {
      background: $color-white-light;
      border: 0;
      height: 50px;
      border-radius: 50px;
      font-size: 18px;
      padding-left: 8px;
      padding-right: 8px;
    }
    .select__control_is--focused {
      background-color: red;
    }
  }
}
