.list-filter {
  display: flex;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 380px;
  padding: 5px 10px;
  border-radius: 10px;
}

.filter-rows {
  .toggle {
    position: relative;
    z-index: 9;
    min-width: 231.67px;
  }

  .toggle {
    width: 100%;
    label {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3.5px;
      font-size: 12px;
      color: #000;

      input {
        max-width: 85px;
        padding: 3px 3px;
        font-size: 10px !important;
      }

      p {
        width: 100%;
        font-size: 12px;
      }
    }
  }
}
