.third-party-form {
  margin-top: 15px;

  &:hover {
    cursor: pointer;
  }

  .input > input, .input textarea{
    font-family: 'Maven', sans-serif;
    font-size: 16px;
  }
}
