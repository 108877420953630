.cmp-alert {
  position: fixed;
  z-index: 999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(2555, 2555, 255, 0.7);

  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.cmp-alert .alert-content {
  background-color: #f8f8f8;
  padding: 20px 30px;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 99;
  border-radius: 5px;
  max-width: 370px;
  color: #666666;
  line-height: 20px;
  position: relative;
}

.cmp-alert .alert-content p {
  text-align: center;
  color: #00587f;
  margin-bottom: 14px;
  padding-left: 40px;
  padding-right: 40px;
  line-height: 1.2;
  font-size: 24px;
  word-break: break-all;
  white-space: initial;
  font-weight: 500;
}

.cmp-alert .confirm-box {
  margin-top: 30px;
}

.cmp-alert .confirm-box .button {
  font-size: 20px !important;
  color: #fff;
}

.cmp-alert .alert-content .close-btn {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.cmp-alert .alert-title {
  word-break: normal !important;
}
