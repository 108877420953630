@use '../../styles/variables' as *;

.working-hours {
  font-size: 18px;
  line-height: 31px;
  padding: 0 24px 40px;
  .hours-line {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    svg {
      width: 100%;
      height: auto;
      fill: $color-yellow;
      display: block;
    }
    .hours-icon {
      flex: 0 0 22px;
      margin-right: 16px;
    }
    .hours-day {
      color: $color-yellow;
      flex: 0 0 10ch;
    }
    .hours-hours {
      flex: 0 0 12ch;
      text-align: center;
    }
    .expand-icon {
      flex: 0 0 24px;
      transition-duration: 0.3s;
      transition-property: transform;
      cursor: pointer;
      &.expanded {
        transform: rotate(180deg);
      }
    }
  }
}
