.date-details-datepicker {
  .calendar {
    background-color: #bad4dc !important;
  }
}

.preferred_date > div {
  width: 100%;
  border-radius: 5px;
  background-color: #bad4dc !important;
  div {
    height: 41px;
  }
  fieldset {
    border: none;
  }
}
.damage-image-list.appointments-license-plate-img-wrapper{
  justify-content: center;
  .image-container {
    width: 230px;
    height: 140px;
  }
}