@use '../../../styles/variables' as *;

.edit-profile-section {
  display: flex;
  flex-wrap: wrap;

  .edit-user-info .button-list {
    .close:after {
      display: none;
    }
    .close:before {
      display: none;
    }
  }
}

.edit-user-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 60% !important;
  flex-wrap: wrap;

  input {
    width: 100%;
    padding: 0px 13px;
    margin: 0;
  }

  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }

  .customer-number-info {
    pointer-events: none;
    cursor: default;
  }

  input[type='file'] {
    display: inline-block;
    width: 42px;
    height: 34px;
    position: absolute;
    left: 6px;
    border-radius: 50%;
    padding: 0;
    top: 0;
    max-height: 42px;
    opacity: 0;
    cursor: pointer !important;

    &:hover {
      cursor: pointer !important;
    }
  }

  .three-quarters {
    max-width: 238px;

    input {
      max-width: 238px;
      width: 238px;
    }
  }

  .button.is-success {
    color: #fff;
  }

  .no {
    max-width: 80px;
    width: 80px;
  }

  .picker-label {
    color: #00587f;
    font-size: 18px;
    //margin-top: 16px;
  }

  input {
    max-height: 32px;
    line-height: 32px;
    padding-left: 13px;
    padding-right: 13px;
    color: $color-blue-dark;
    font-size: 15px;
    background: #eef5f8;
  }

  .input-label {
    color: $color-marker-passenger-car;
    font-size: 18px;
    margin-top: 16px;
  }

  .input-label p {
    color: $color-marker-passenger-car !important;
    font-size: 18px;
    margin-bottom: 6px;
  }

  .select-box {
    margin-bottom: 0;
    color: $color-input-text;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    padding-left: 13px;
    padding-right: 13px;
    text-align: left;
    height: 32px;
    line-height: 32px;
    border-radius: 25px;
    max-width: 230px;
    width: 100%;
    border: none;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background:
      url(../../../assets/img/chevron-down.png) no-repeat right,
      $color-input-background;
    background-position: 95% 14px;

    &::-ms-expand {
      display: none;
    }

    &:after {
      content: '';
      padding: 0 8px;
      font-size: 12px;
      position: absolute;
      right: 8px;
      top: 4px;
      z-index: 1;
      text-align: center;
      width: 10%;
      height: 100%;
      pointer-events: none;
      box-sizing: border-box;
    }

    &:hover {
      cursor: pointer;
      z-index: 9;
    }

    svg {
      color: $color-yellow;
    }

    &:hover {
      cursor: pointer !important;
    }

    > div {
      border: unset;
      background-color: $color-input-background;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .input-language {
    margin-top: 17px;
    display: block;
  }

  .edit-row-left {
    width: 151px;
    max-width: 151px;
    white-space: nowrap;
    text-overflow: ellipsis;

    .icons {
      z-index: 7;
      position: absolute;
      bottom: -66px;
      left: 15px;
      margin-left: 6px;
      margin-right: 6px;

      &:hover {
        cursor: pointer;
      }
    }

    .icon-bg {
      right: 10px;
      bottom: 10px;
      display: inline-block;
      background: #ffd500;
      width: 42px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      padding-top: 9px;
      margin-left: 6px;
      margin-right: 6px;
      cursor: pointer;

      svg {
        color: #000;
        cursor: pointer;

        &:hover {
          cursor: pointer;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .icon-bg-remove {
      right: 10px;
      bottom: 10px;
      display: inline-block;
      background: #e84d0e;
      width: 42px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      padding-top: 9px;

      svg {
        color: $color-black;
      }
    }

    .camera {
      position: absolute;
      z-index: 401;
      right: 10px;
      bottom: 10px;
      display: inline-block;
      background: #ffd500;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      text-align: center;

      &:hover {
        cursor: pointer;
      }

      svg {
        margin-top: 10px;
        width: 20px;
        color: $color-black;
      }
    }

    .img-row {
      position: relative;
      margin-bottom: 3px;

      &:after {
        content: '';
        position: absolute;
        background: #94b9c9;
        width: 135px;
        height: 135px;
        border-radius: 50%;
        z-index: 2;
        left: 7px;
        top: -10px;
      }

      &:before {
        content: '';
        position: absolute;
        background: #c2d7e0;
        width: 146px;
        height: 146px;
        border-radius: 50%;
        z-index: 2;
        left: 0;
        top: -18px;
      }
    }

    .img-row-down {
      margin-bottom: 60px;
    }

    .img-container {
      width: 115px;
      text-align: center;
      height: 115px;
      z-index: 400;
      margin-left: auto;
      margin-right: auto;
    }

    img {
      width: 115px;
      position: relative;
      display: block;
      z-index: 4;
      margin: 0 auto;
      border-radius: 50%;
    }
  }

  .edit-row-and {
    max-width: 385px;
    width: 100%;

    .is-success {
      background: #95c11e;
      border-radius: 10px;
      height: 50px;
      line-height: 50px;

      svg {
        transform: rotate(45deg);
      }

      &:hover {
        opacity: 0.8;
      }

      .button-text {
        font-size: 20px !important;
        color: $color-white !important;
        font-weight: 500;
      }
    }

    .input-list {
      display: flex;
      flex-direction: column;
    }

    .edit-row-password {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-left: 25px;

      .input-label {
        font-size: 18px;
        color: $color-blue-middle;
        margin-bottom: 7px;
      }

      input {
        height: 28px;
        width: 328px;
        background: $color-input-background;
        border-radius: 5px;
        font-size: 15px;
        color: $color-blue-dark;
        margin-bottom: 20px;
      }

      .shield {
        width: 156px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-bottom: 20px;
      }
    }
  }

  .edit-row-right {
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;

    .is-warning {
      background: $color-yellow;
      border-radius: 10px;
      width: 360px;
      height: 50px;
      line-height: 50px;

      &:hover {
        background-color: $color-yellow-dark-light;
      }

      svg {
        color: $color-marker-transporter;
      }

      .button-text {
        font-size: 20px !important;
        color: $color-marker-transporter !important;
        font-weight: 500;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 14px;
        padding-right: 14px;
      }
    }

    .is-danger {
      border-radius: 10px;
      background: $color-red;
      width: 360px;
      height: 50px;
      line-height: 50px;

      &:hover {
        opacity: 0.8;
      }

      svg {
        color: $color-marker-transporter;
      }

      .button-text {
        font-size: 20px !important;
        color: $color-input-text !important;
        font-weight: 500;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 14px;
        padding-right: 14px;
      }
    }

    .is-success {
      background: $color-green;
      border-radius: 10px;
      width: 360px;
      height: 50px;
      line-height: 50px;

      &:hover {
        opacity: 0.8;
      }

      .button-text {
        font-size: 20px !important;
        color: $color-white !important;
        font-weight: 500;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 14px;
        padding-right: 14px;
      }
    }

    .button-list {
      flex-direction: column;
      max-width: 360px;
      width: 100%;
      display: flex;
      height: 100%;
      justify-content: flex-end;
      padding-bottom: 12px;

      button {
        max-width: 360px;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .edit-row-center {
    max-width: 500px;
    width: 100%;
    padding-left: 120px;
    padding-right: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    button {
      height: 50px;
      border-radius: 10px;
    }

    .cmp-input-label {
      font-size: 18px;
    }

    .name-surname .input-label {
      max-width: 163px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .input-list.street {
      .full .input-label {
        max-width: 80px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .title {
      max-width: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .button-list {
    justify-content: space-between;

    .button {
      margin-bottom: 10px;
    }
  }
}
.edit-password-form {
  display: flex;
  height: 100%;

  .edit-row-password {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-left: 25px;
    width: 346px;

    button {
      height: 50px;
      border-radius: 10px;
      width: 335px;
    }
  }
}
