.add-driver-clickable {
  padding-top: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span{
    font-size: 16px;
    margin-right: 10px;
  }
}

.vehicle-form .details-row {
    width: 250px !important;
  }
