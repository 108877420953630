.applyButton {
  top: 23px;
  height: 30px;
  max-width: 125px;
  border-radius: 14px;
  background: #95c11e;
  margin-left: 30px;
  margin-top: 26px;
  cursor: pointer;
  padding: 5px 35px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  outline: none;
  font-size: 20px;
}

.apply-text {
  flex-grow: 1;
  font-family: 'Maven', sans-serif;
  font-weight: 500;
  line-height: 1.15;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.apply-span {
  margin-left: 5px;
  //font-size: 20px;
}
