@use '../../../../styles/variables' as *;

.service-order {
  margin-top: 15px;

  .damage-image-list {
    display: flex;
    justify-content: center;
    align-items: center;

    .image-container {
      width: 230px;
      height: 140px;
    }
  }
}

.select-box {
  margin-bottom: 0;
  color: $color-input-text;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  padding-left: 13px;
  padding-right: 13px;
  text-align: left;
  height: 32px;
  line-height: 32px;
  border-radius: 25px;
  max-width: 230px;
  width: 100%;
  border: none;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background:
    url(../../../../assets/img/chevron-down.png) no-repeat right,
    $color-input-background;
  background-position: 95% 14px;

  &::-ms-expand {
    display: none;
  }

  &:after {
    content: '';
    padding: 0 8px;
    font-size: 12px;
    position: absolute;
    right: 8px;
    top: 4px;
    z-index: 1;
    text-align: center;
    width: 10%;
    height: 100%;
    pointer-events: none;
    box-sizing: border-box;
  }

  &:hover {
    cursor: pointer;
    z-index: 9;
  }

  svg {
    color: $color-yellow;
  }

  &:hover {
    cursor: pointer !important;
  }

  > div {
    border: unset;
    background-color: $color-input-background;

    &:hover {
      cursor: pointer;
    }
  }
}
