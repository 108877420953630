.filter-rows {
  flex-direction: row;
  justify-content: space-between;
  min-width: 235px;
  background: #fff;
  padding: 12px;
}

.filter-rows {
  label > p {
    line-height: 14px;
  }
}
