@use '../../../../styles/variables' as *;

.summary-main-container {
  background: $color-white-light;
  position: relative;
  width: 20vw;
  height: 100%;
  padding: 10px 25px;
  margin-bottom: 100px;
  margin-top: 25px;

  p {
    font-size: 16px;

    &.input-label {
      font-size: 18px;
      margin: 20px 0 0 0;
    }

    &.error-message {
      color: red;
    }
  }

  .card-title {
    color: $color-blue-high;
    font-size: 22px;
    font-weight: 500;
  }
}
