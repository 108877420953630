.shield {
  width: 156px;
  display: block;
}

form {
  width: 100%;
}

.password-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  .input.full{
    margin-right: 0;
    input{
      font-size: 16px;
    }
  }
}

.input-eye{
  position: relative;
  width: 346px;

  img{
    position: absolute;
    width: 22px;
    top: 40px;
    right: 13px;

    &:hover{
      cursor: pointer;
    }
  }
}
