.garage-details {
  margin-top: 15px;
  .toggler .react-toggle-track {
    width: 20px;
    height: 6px;
  }

  .toggler {
    margin-top: 6px;
  }

  .toggler .react-toggle-thumb {
    width: 10px;
    height: 10px;
    top: -2px;
    left: -1px;
  }

  .toggler .react-toggle--checked .react-toggle-thumb {
    left: 14px;
    border-color: #FFD500;
    background-color: #FFD500;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.41);
  }

  .toggler .react-toggle--checked .react-toggle-track {
    background-color: #FFD500;
  }

  .toggler .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    border-color: #FFD500;
  }


  .toggler .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #FFD500 !important;
  }

  .setting .toggler .react-toggle--checked .react-toggle-thumb {
    left: 16px;
    border-color: #FFD500;
  }
}
