.appointment-calendar {
  background: #eef5f8;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  max-width: 365px;
  width: 30px;
  overflow:hidden;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  cursor: pointer;
}

.appointment-calendar.calendar-expand {
  width: 100%;
}

.appointment-calendar .datepicker-tongue {
  display: inline-block;
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
}

.appointment-calendar.calendar-expand .datepicker-tongue {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}


.appointment-calendar .form-cont {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.appointment-calendar .appointment-btn-group {
  padding: 20px 30px;
  menu, li, .popover {
    width: 295px;
  }
}
