.license-input-wrapper {
  position: relative;
  margin-right: 10px;
  display: inline-flex;
}

.license-input {
  display: block;
  min-width: 0;
  width: 100%;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  border: none;
  box-sizing: content-box;
  background: none;
  border: 0;
  border-bottom: 1px solid #ccc;
}

.license-input ~ .border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #95a5a6;
}

.license-input:focus ~ .border {
  width: 100%;
  transition: 0.5s;
}
