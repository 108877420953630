@use '../../../styles/variables' as *;

.button {
  height: 50px;
  width: 100%;
  padding: 0 30px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  max-width: 361px;
  outline: none;
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 24px;
    height: auto;
  }

  .button-text {
    flex-grow: 1;
    font-family: 'Maven', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.15;
    word-break: break-all;
  }

  .spacer {
    width: 24px;
  }

  &.is-small {
    height: 40px;
    border-radius: 5px;
    padding: 0 10px;
    .button-text {
      font-size: 18px !important;
      line-height: 1.17;
      font-weight: 300;
      margin-left: 20px;
      word-break: break-all;
    }
  }

  &:focus {
    box-shadow: none !important;
  }

  &.icon-only {
    height: 50px;

    svg {
      width: auto;
      color: white;
      height: 50px;
    }
  }

  &.is-flat {
    box-shadow: none;
  }

  // TODO put colors to variables
  &.is-primary {
    background: linear-gradient(to right, #8fc21e, #71c122);
  }

  &.is-link {
    background: linear-gradient(to right, #00557b, #00293d);
  }

  &.is-danger {
    background: linear-gradient(to right, #e8490f, #e82a0e);
  }

  &.is-warning {
    background-color: #ffd500;
  }
  &.is-success {
    background: linear-gradient(to right, #91c11e, #91c11e);
    color: #fff;
  }
  &.is-disabled {
    background: linear-gradient(to right, #dadada, #dadada);
  }
  &.apply-btn {
    background: black;
    margin: 1200px;
  }
  &.disabled {
    opacity: 0.5;
  }
}
