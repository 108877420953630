@use '../../../styles/variables' as *;

.flexColumn {
  display: flex;
  flex-direction: column;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.label {
  color: $color-blue-middle;
  font-weight: 500;
  font-size: 16px;
}

.text {
  color: $color-black-gray;
  font-size: 16px;
  margin-left: 10px;
}
