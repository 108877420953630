.tabs ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}
/* Style the buttons that are used to open the tab content */
.tab ul li {
  background-color: inherit;
  display: block;
}
