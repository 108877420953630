.cancel-appointment-btn {
  background: rgb(232, 77, 14);
  border: none;
  outline: none;
  color: #fff;
  padding: 5px 10px;
  margin-top: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.appointment-screen-loader.modal {
  background: rgba(0, 39, 60, 0.6);
}
