@use '../../../styles/variables' as *;

.gas-station-popup {
  position: relative;
  z-index: 9999;

  .info-container {
    position: absolute;
    bottom: 22px;
    transform: translate(-50%);
    color: $color-white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    white-space: nowrap;
    line-height: 1.2;
    padding: 1px 0;
    width: 70px;

    .info-header {
      align-items: center;
      padding: 0 3px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      svg {
        flex-shrink: 0;
        display: block;
        height: 14px;
        width: auto;
      }
      .header-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        line-height: 1.21;
      }
    }

    .info-data {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding-left: 7px;
      overflow: hidden;
      .price-body {
        font-family: 'DS', sans-serif;
        font-size: 24px;
        line-height: 1.21;
      }
      .price-tail {
        margin-right: 2px;
        font-family: 'DS', sans-serif;
        font-size: 16px;
        line-height: 1.21;
      }
      .data-units {
        font-size: 20px;
        line-height: 1.4;
      }
    }

    .triangle {
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translate(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 8px 0 8px;
      border-color: transparent;
    }

    &.high {
      background-color: $color-price-high;
      .triangle {
        border-top-color: $color-price-high;
      }
    }

    &.middle {
      background-color: $color-price-middle;
      .triangle {
        border-top-color: $color-price-middle;
      }
    }

    &.low {
      background-color: $color-price-low;
      .triangle {
        border-top-color: $color-price-low;
      }
    }
  }
}
