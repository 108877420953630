.damage_border_red {
  border-right: 5px solid #E84D0E;
}

.damage_border_yellow {
  border-right: 5px solid #FFD500;
}

.damage_border_white {
  border-right: 5px solid #c9c9c9;
}

.damage_border_green {
  border-right: 5px solid #95C11E;
}

.damage_border_green_white {
  border-right: 5px solid #95C11E;
}
