@use '../../../../styles/variables' as *;

.details-container {
  .damage-type-size {
    max-width: 170px;
    margin-right: 0 !important;
    padding-right: 10px !important;
    line-height: 27px;
  }
  .details-damage .details-title {
    height: 50px;
    line-height: 17px;
    margin-bottom: 0 !important;
  }
  .details-license .license-plate {
    margin-bottom: 8px !important;
    margin-left: 0 !important;
    margin-top: 4px !important;
  }
  .details-column {
    margin-right: auto;
    .damage-type {
      margin-bottom: 8px;
      font-size: 26px;
      font-weight: 500;
      margin-right: 20px;
    }
    .details-line {
      color: $color-marker-transporter;
      margin-bottom: 11px;
      .inspection-span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        svg {
          margin-left: 5px;
        }
        img {
          margin-left: 5px;
        }
      }
    }
    .details-row {
      display: inline-block;
      width: 200px;
      color: $color-marker-passenger-car;
      font-size: 16px;
    }
    .details-license {
      display: flex;
    }
    .details-title {
      font-weight: 500;
      margin-bottom: 16px;
      text-transform: uppercase;
    }
    .details-line,
    .license-plate {
      margin-bottom: 12px;
    }
    .license-plate {
      display: flex;
      height: 25px;
      margin-bottom: 0;
      margin-top: 10px;
      margin-left: 18px;
      width: unset;
    }
    .details-driver {
      display: flex;
      svg {
        margin-right: 12px;
      }
      .details-line {
        display: flex;
      }
    }
  }
}
.damage-export {
  max-width: 309px;
  margin: 0 auto;
  background: $color-yellow;
  border-radius: 11px;
  height: 50px;
  text-align: center;
  color: $color-blue-dark;
  position: relative;
  font-size: 20px;
  line-height: 50px;
  font-weight: 500;

  .title_btn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 49px;
    padding-right: 49px;
    height: 50px;
    text-align: center;
    color: #00273c;
    font-size: 20px;
    line-height: 50px;
    font-weight: 500;
    max-width: 309px;
    display: inline-block;
  }

  svg {
    position: absolute;
    left: 24px;
    top: 13px;
    color: #1a1600;
  }
  &:hover {
    cursor: pointer;
    background-color: $color-yellow-dark-light;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7);
  }
}
