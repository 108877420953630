.date-picker {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
  margin-top: 8px;

  input {
    max-width: 85px;
  }

  .end {
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
  }

  .calendar {
    min-width: 100px;
    padding: 5px 17px;
    border: 1px solid #707070;
    border-radius: 1px;
    display: flex;
    padding-left: 3px;
    align-items: center;
  }

  .calendar svg {
    width: 13px;
    height: 12px;
    left: unset;
    right: 2px;
    top: 1px;
  }

  .calendar-icon {
    font-size: 10px;
    line-height: 13px;
  }
  &:hover {
    cursor: pointer;
  }
}
