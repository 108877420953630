@use '../../../styles/variables' as *;

.user-avatar {
  position: relative;

  .img-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    min-width: 64px;
    width: 64px;
    height: 64px;
    background: $color-white;
    justify-content: center;
    align-items: center;

    &.micro {
      width: 32px;
      height: 32px;
      min-width: 32px;
    }
    &.small {
      width: 50px;
      height: 50px;
      min-width: 50px;

      span {
        font-size: 22px;
      }
    }

    &:after {
      content: '';
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding-bottom: 100%;
    }

    > img {
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    > svg {
      height: 100%;
      width: auto;
      padding: 2px;
      fill: $color-white;
      background-color: #c9c9c9;
    }

    span {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      color: $color-blue-dark;
      font-size: 30px;
      font-weight: 100;
      user-select: none;
    }
  }

  .icon-container {
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border-width: 0;
    overflow: hidden;
    background: transparent;
    z-index: 999;

    svg {
      height: 18px;
      width: auto;
      display: block;
    }

    &.small {
      svg {
        height: 18px;
      }
    }

    &.has-background-color {
      padding: 2px;
    }

    &.transparent {
      background-color: transparent;
    }

    &.green {
      background-color: $color-green !important;
    }

    &.white {
      background-color: $color-white !important;
    }

    &.black {
      background-color: $color-black !important;
    }

    &.red {
      background-color: $color-red !important;
    }

    &.blue {
      background-color: $color-blue-middle !important;
    }

    &.yellow {
      background-color: $color-yellow !important;
    }
    &.grey {
      background-color: $color-grey-dark !important;
    }

    &.green-white {
      border-color: #95c11e;
      background-color: #fff;
      svg {
        color: #95c11e;
      }
    }
  }
}
