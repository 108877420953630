.vehicle-info-row {
  display: flex;
  margin-bottom: 40px;
}

.vehicle-info-row .user-avatar {
  padding-right: 15px;
}

.vehicle-info-row .info-name {
  font-size: 16px;
  line-height: 1.13;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: #fff;
}

.vehicle-info-row .info-tags {
  color: #fff;
  font-size: 14px;
  line-height: 1.13;
  font-weight: 300;
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vehicle-info-row .license-plate {
  padding-top: 5px;
  max-width: 100px;
}

.vehicle-info-row .license-plate .license-container {
  width: 100%;
}
