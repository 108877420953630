@use '../../../styles/variables' as *;

.card-list {
  display: inline-block;

  .empty-message-damages {
    text-align: center;
    font-size: 16px;
    display: block;
    border-radius: 1px;
    max-width: 320px;
    background: #e84d0e;
    padding: 3px 4px 7px 4px;
    color: $color-white;
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translate(-50%, 0);
    transition: all 0.3s;

    &.toast-none {
      opacity: 0;
    }
  }

  [data-id='empty-card'] {
    margin-top: 22px !important;
    text-align: center;
    background: $color-white;
    &:hover {
      background: $color-white;
    }

    .left-section {
      width: 100% !important;
      margin-top: 26px;
    }

    .right-section {
      display: none;
    }

    .top-section {
      flex-direction: column;
    }

    .text-container {
      margin: 0 auto;
    }

    .primary-text {
      font-size: 14px !important;
    }
  }

  .pagination-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    width: $card-width;
    position: relative;

    .button {
      &.disabled {
        opacity: 0.3;
      }
    }

    .pagination-info {
      white-space: nowrap;
    }

    input {
      border-radius: 0;
      border: 1px solid rgba(0, 0, 0, 0.05);
      padding: 8px;
    }
  }
}
.damage-card {
  background-color: #fff;
  margin-bottom: 8px;
}
