@use "reset.scss";
@use "variables.scss";
@import "~react-toastify/dist/ReactToastify.css";


/*** Fonts ***/

@font-face {
  font-family: 'License-Plate';
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/License_Plate/License-Plate.ttf") format('woff');
}

@font-face {
  font-family: 'Maven';
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Maven_Pro/MavenPro-Regular.ttf") format('woff');
}

@font-face {
  font-family: 'Maven';
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/Maven_Pro/MavenPro-Medium.ttf") format('woff');
}

@font-face {
  font-family: 'Maven';
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Maven_Pro/MavenPro-Bold.ttf") format('woff');
}

@font-face {
  font-family: 'DS';
  font-style: normal;
  src: url("../assets/fonts/ds_digital/DS-Digi.ttf") format('woff');
}





/*** Common Styles ***/

p, span {
  font-size: 14px;
  padding: 0;
  margin: 0;
}


body {
  font-family: 'Maven', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#root {
  height: 100%;
}

.app-wrapper {
  height: 100%;
  width: 100%;
}

.expandableCard {
  max-width: 363px;
  border-radius: 7px;
}

/** Helper classes **/

.details-container {
   width: 100%;
   padding-left: 55px;
   height: 100%;
   display: flex;
   flex-direction: column;
}


.details-content {
  padding: 32px 30px 0 48px;
  height: 100%;
}

.flex-1 {
 flex: 1;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-evenly {
  justify-content: space-evenly;
}

.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-center {
  align-items: center;
}
.self-center {
  align-self: center;
}

.text-center {
  text-align: center;
}

.p-b-2 {
  padding-bottom: 2rem;
}

.h-100 {
  height: 100%;
}

.overflow-auto {
  overflow: auto;
}

.relative {
  position: relative;
}
.grow {
  flex-grow: 1;
}

/** Lists in app common styles & classes **/
.list-wrapper {
  min-height: 100vh;
}

.list-wrapper .maps-container{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.list-view {
  padding-left: 50px;
  overflow: auto;
  max-height: 100vh;
  min-height: 100vh;
}

.list-view .list-container {
  max-width: 425px;
  position: relative;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin-left: 20px;
  padding-bottom: 50px
}

.list-container {

  &::-webkit-scrollbar {
    display: none;
  }
}

.list-container .card {
  background-color: #fff;
  cursor: pointer;
  min-height: 88px !important;
}

.list-add-button {
  position: fixed;
  z-index: 9999;
  bottom: 3px;
  padding: 10px;
  display: flex;
  width: 377px;
  margin-left: 50px;
}

/** Map & List styles on Details page (scrolling list & map to right) **/

#vehicle-container .list-container {
  height: calc(100vh - 200px);
  overflow: auto;
}


#vehicle-container .map {
  overflow: hidden;
}
