.trip-static{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 16px 10px 20px 10px;
  .driver {
    margin-left: 30px;
    display: flex;
    align-items: center;
    .multi-driver{
      display: flex;
      align-items: center;
    .user-avatar{
      svg{
        background-color: #00587F;
      }
    }
    }
    .driver-name-span {
      margin-left: 6px;
      font-size: 16px;
    }
  }
  .type{
    margin-left: 40px;
    display: flex;
    align-items: center;
    svg{
      margin-right: 12px;
    }
  }
  }

.trip-static > div {
  font-size: 16px;
  color: #00587F;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
