@use '../../../styles/variables' as *;

.add-ticket-container {
  padding: 20px 40px;

  .file-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding-top: 10px;
  }

  .submit-ticket-container {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 30px;

    button {
      max-width: 100%;
    }
  }

  .ticket-add-form {
    padding-top: 20px;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    color: $color-blue-middle;

    .input > input {
      font-size: 16px;
    }

    .form-field {
      div .input.full p {
        display: none;
      }
      .cmp-date-picker {
        .calendar {
          border-radius: 5px;
          height: 34px;
          margin-top: 10px;
        }
        p {
          display: none;
        }
        .react-datepicker__input-container {
          //width: 86%;
          border-radius: 5px;
        }
      }
    }

    > .button {
      margin-left: 30px;
      margin-right: 30px;
      width: auto;
      padding: 0 30px;
    }
  }
  .remove-file-button {
    background: $color-yellow !important;
    padding: 0 5px;
    height: 40px;
    width: 40px;
    min-width: 40px !important;
    color: black !important;
    position: absolute !important;
    top: 10px;
    right: 20px;
  }
}
