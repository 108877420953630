.create-appointment {
  background-color: #eee;
  z-index: 999;
}

.need-done {
  max-width: 377px;
  width: 100%;
  background: #EEF5F8;
  padding: 8px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 55px;

  &__title {
    color: #00273C !important;
    font-size: 20px !important;
    font-weight: 500;
    padding-left: 6px;
  }

  &__subtitle {
    color: #00273C !important;
    font-size: 20px !important;
    font-weight: 500;
    padding-left: 6px;
    margin-top: 28px;
    margin-bottom: 2px;
  }

  .appointment-what-needs:disabled {
    opacity: 0.5;
  }

  .cancel-add-damage-button {
    position: absolute;
    z-index: 99;
    width: 60px;
    height: 30px;
    top: 10px;
    left: 385px;

    button {
      background-color: transparent;
      border: none;
      border-radius: 50px;

      &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .arrow_button {
    margin-bottom: 14px;
  }
}

.task-selector {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 16px;
  padding-left: 6px;

  .task-box {
    min-width: 160px;
    max-width: 160px;
    margin-bottom: 16px;
    height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .icon-block {
      display: flex;
      align-items: center;
      height: 167px;
    }

    span {
      display: inline-block;
      text-align: center;
      color: #00587F;
      font-size: 18px;
      position: absolute;
      bottom: 23px;
      left: 50%;
      transform: translate(-50%, 10px);
      max-width: 140px;
      margin-bottom: 0;
      width: 100%;
    }
  }
}
