.damage-additional-information {
  padding-top: 50px;
}


.damage-additional-information .title {
  font-size: 18px;
  color: #00587f;
  padding-bottom: 15px;
}
