.license-tab {
  .license-images {
    display: flex;
    flex-wrap: wrap;

    img {
      padding: 4px;
      cursor: pointer;
      object-fit: contain;
      width: auto;
      max-width: 100%;
      height: auto;
      margin-right: 20px;
      max-height: 360px;
    }
  }
}

.icon-user-state {
  display: flex;
  svg {
    margin-left: 5px;
  }
}
