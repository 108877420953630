@use '../../../styles/variables' as *;

.map-marker {
  position: relative;
  font-family: 'Maven', sans-serif;

  .marker-container {
    position: absolute;

    &.icon-marker {
      height: 33px;
      bottom: 0px;
      transform: translate(-50%);
      z-index: 1;
    }
    &.dot-marker {
      height: 10px;
      width: 10px;
      top: -8px;
      left: -8px;
      border: 3px solid $color-white;
      border-radius: 50%;
      z-index: 0;
      &.high {
        background-color: $color-price-high;
      }
      &.middle {
        background-color: $color-price-middle;
      }
      &.low {
        background-color: $color-price-low;
      }
    }

    &.small_transporter-mark {
      z-index: 999;
    }

    &.transporter-mark {
      z-index: 999;
    }

    &.station-mark {
      z-index: 997;
    }

    &.ServicePartner-mark {
      z-index: 998;
    }

    .icon-background {
      position: absolute;
      top: 3px;
      left: 3px;
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background-color: $color-white;
      z-index: -1;
    }

    .marker-icon {
      display: block;
      height: 100%;
      width: auto;
    }

    .marker-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      left: 13px;
      min-height: 16px;
      max-width: 72px;
      padding-left: 14px;
      font-weight: 500;
      border: 1px solid $color-black;
      border-left: none;
      border-radius: 0 4px 4px 0;
      background-color: $color-white;
      z-index: -1;
      .info-line1 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 8px;
        line-height: 1.25;
        padding-right: 4px;
      }
      .info-line2 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 6px;
        line-height: 1.17;
        color: $color-blue-middle;
      }
      &.passenger_car {
        border-color: $color-marker-passenger-car;
      }
      &.transporter {
        border-color: $color-marker-transporter;
      }
      &.service {
        border-color: $color-marker-service;
      }
      &.cluster {
        border-color: $color-marker-cluster;
      }
    }

    .marker-dot {
      position: absolute;
      top: 15px;
      left: 20px;
      height: 7px;
      width: 7px;
      border-radius: 50%;
      border: 1px solid $color-black;
      background-color: $color-red;
      &.green {
        background-color: $color-green;
      }
      &.red {
        background-color: $color-red;
      }
      &.grey {
        background-color: $color-grey-dark;
      }
    }
  }
}
