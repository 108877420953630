.card {
  .card-inner {
    .top-section {
      .right-section {
        width: 65%;

        > * {
          margin: 0;
        }

        .contractTax {
          font-size: 10px;
        }

        .contractSum {
          margin-top: 4px;
        }
      }

      .left-section {
        width: 40%;
      }
    }
  }
}

.list-container {
  .contract_border_grey {
    border-right: 5px solid #c9c9c9; // Grey color for 'past' status
  }

  .contract_border_green {
    border-right: 5px solid #95c11e; // Green color for 'active' status
  }
}

#contract-list {
  .contract-card {
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 7px;
    .card {
      .card-inner {
        .top-section {
          .left-section {
            width: 90%;
            svg.contract-document-icon {
              padding: 5px;
            }
          }
        }
      }
    }
  }
}
