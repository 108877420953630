@use '../../../styles/variables' as *;

.license-details {
  .license-images {
    display: flex;
    flex-wrap: wrap;
    img {
      padding: 4px;
      cursor: pointer;
      object-fit: contain;
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }
}
