.edit-tags-title {
    font-weight: 300;
    text-align: center;
    font-size: 26px;
    width: 70%;
    margin: 0 auto;
}

.tags-container {
    padding: 20px 0;
}


.tags-container .tag-list-label {
    font-weight: 300;
    font-size: 18px;
    color: #00587F;
}

.tags-container  .tags-list {
    padding-top: 10px;
}

.tags-container .tags-list .tag-item {
    display: flex;
    align-items: center;
    word-break: break-word;
}


.tag-list-container > svg {
    position: relative;
    top: -25px;
}
.tag-item .remove-tag {
    cursor: pointer;
    color: #e74c3c;
    display: inline-flex;
    padding-top: 3px;
}
.tag-item p {
    color: #313131;
    padding-left: 10px;
    font-size: 16px;
    white-space: initial;
    margin: 0;
}

.save-tags {
    color: #fff;
    align-self: center;
}
