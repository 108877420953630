@use '../../../styles/variables' as *;

.driver-popup {
  position: relative;
  z-index: 9999;
  .info-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 52px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    width: 196px;
    line-height: 1.2;
    transform: translate(-50%);
    background-color: $color-white;
    .info-header {
      display: flex;
      padding: 26px 8px 0 8px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      color: $color-white;
      background: radial-gradient(circle, $color-blue-light 0%, $color-blue-dark 100%);
      font-size: 12px;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      .header-name {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        line-height: 17px;
        padding: 7px 0px;
      }
      .header-car {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
        line-height: 15px;
      }
    }
    .popup-footer {
      padding: 4px 8px 8px;
      font-size: 11px;
      .customer-city {
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 8px;
        line-height: 10px;
        padding-bottom: 4px;
        color: $color-blue-light;
      }
      .customer-data {
        max-width: 100%;
        .data-line {
          display: flex;
          flex-wrap: nowrap;
          align-items: flex-end;
          .line-start {
            flex: 1 1;
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          svg {
            flex: 0 0 12px;
            display: block;
            height: 12px;
            margin-right: 2px;
          }
          .line-end {
            flex: 0 0 38px;
            font-size: 8px;
            white-space: nowrap;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .user-avatar {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -42%);
      .img-container {
        border: 2px solid $color-blue-light;
      }
    }
    .triangle {
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translate(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 8px 0 8px;
      border-color: $color-white transparent transparent transparent;
    }
  }

  .info-tags {
    color: #ffd500;
    padding: 0px 10px;
    word-break: break-all;
    white-space: normal;
  }
}
