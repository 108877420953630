.header-info-row .trip-heading-title {
  font-size: 22px;
  color: #ffd500;
  margin-left: 15px;
  margin-bottom: 30px
}

.logbook-details {
  width: 100%;

  .trips-two-dots {
    display: flex;
    margin-bottom: 20px;
  }

  .driver-info {

    .driver-line {
      display: flex;
      align-items: baseline;
      margin-top: 10px;

    .license-popup {
      left: 400px;
    }


      .driver-select-cmp {
        width: 100%;

        .select-driver-label {
          display: none;
        }

        .add-driver-clickable {
          display: none;
        }
      }
    }

    .type {
      display: flex;
      align-items: center;
    }
  }




  .driver-span {
    margin-top: 20px;
    margin-bottom: 15px;
    margin-right: 15px;
  }
}

.close-button {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 15px;
}

.map-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
  z-index: 2;
}
.map-loader img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#trip-details .trip-btn {
  margin: 0 auto;
}

#trip-details .trip-notice {
  padding: 10px 15px;
  max-width: 350px;
  margin: 0 auto;
  margin-bottom: 25px;
}
