.header-profile-user {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  word-break: break-word;
  white-space: pre;
  margin-top: 25px;

  .customer-id {
    font-size: 16px;
    line-height: 1.13;
    color: #fff;
  }
}


@media screen and (max-width: 576px) {

  .sidebar {
    position: relative;
    min-height: 56px;
    width: 100%;
    opacity: 1;
    background: #00273C;
    transition: none;
  }

}
