.edit-user-info {
  max-width: 500px;
  height: 100%;

  &.edit{
    display: flex;
    flex-direction: column;
  }

  &.driver{
    flex-direction: column !important;
  }
}

.mt-10 {
  margin-top: 10px;
  .img-preview {
    max-width: 810px;
    justify-content: flex-start;
  }
  .cmp-input {
    max-width: 500px;
  }
}


.btn-edit-license{
  color: white;
}
