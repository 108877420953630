.empty-list-card {
  text-align: center;
  font-size: 18px;
  display: block;
  border-radius: 1px;
  max-width: 320px;
  background: #e84d0e;
  padding: 3px 4px 5px 4px;
  line-height: 20px;
  color: #fff;

  .message {
    margin: 0;
  }
}
