.trip-actions {
  .driver-logbook {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .driver-select-cmp {
      margin-left: 10px;
      width: 100%;
      .select-driver-label {
        display: none;
      }

      .driver-select {
        width: 100%;
      }
    }
    .license-popup {
      left: 68%;
      max-width: 500px;
      width: 35%;
    }
  }
  .type {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
}
