.ticket-finish-modal {
  background-color: rgba(0, 0, 0, 0.35);
  padding-top: 120px;

  .modal-container {
    .modal-header {
      display: none;
    }

    .modal-content {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}

.finish-ticket-form {
  background-color: #fff;
  max-width: 500px;
  border-radius: 10px;
  padding: 30px 20px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  .close-reason-modal {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.finish-ticket-form .title {
  color: #00587f;
  font-size: 16px;
  font-weight: 300;
}

.finish-ticket-form .ticket-options {
  width: 330px;
  margin-bottom: 10px;
}

.ticket-finish-modal .error-message {
  color: red;
  padding-bottom: 5px;
}
