@use '../../../styles/variables' as *;

.service-popup {
  position: relative;
  z-index: 9999;
  .info-container {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    bottom: 52px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    width: 196px;
    line-height: 1.2;
    transform: translate(-50%);
    background-color: $color-white;
    .info-header {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding: 20px 8px 4px 8px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      color: $color-white;
      background: radial-gradient(circle, #058869 0%, #01654d 100%);
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      .header-name {
        max-width: 100%;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.21;
        text-align: center;
      }
    }
    .popup-footer {
      padding: 4px 8px 8px;
      font-size: 11px;
      .customer-city {
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 8px;
        line-height: 10px;
        padding-bottom: 4px;
        color: $color-blue-light;
      }
      .customer-data {
        max-width: 100%;
        .data-line {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          flex-wrap: nowrap;
          align-items: flex-end;
          .line-start {
            flex: 1 1;
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .rating-set {
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;
      transform: translate(0, -47%);
      svg {
        display: block;
        fill: #e8b50e;
        width: 37px;
        height: auto;
      }
    }
    .triangle {
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translate(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 8px 0 8px;
      border-color: $color-white transparent transparent transparent;
    }
  }
}
