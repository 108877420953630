@use '../../styles/variables' as *;

.station-info {
  min-height: 100%;
  margin-top: 15px;
  .input {
    flex: 0 0;
    margin-bottom: 20px;
    input {
      padding: 0 16px;
      height: 36px;
      border-radius: 100px;
      background-color: rgba(255, 255, 255, 0.05);
      color: $color-white;
    }
  }

  .price-line.elem-0 .fuel-type {
    text-transform: capitalize;
  }

  .hours-day {
    font-size: 16px;
  }

  .hours-hours {
    font-size: 16px;
    //text-transform: capitalize;
  }

  .btn-back {
    color: $color-yellow;
    margin-bottom: 8px;
    position: relative;
    padding-left: 20px;
    span {
      position: absolute;
      left: 0;
      top: -1px;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .icon-set {
    padding: 0 28px;
    min-height: 36px;
    height: 36px;
    margin-bottom: 20px;
    justify-content: space-between;
    svg {
      width: 28px;
      height: auto;
    }
  }

  .service-name {
    color: $color-white;
    font-size: 20px;
    line-height: 1.17;
    text-align: center;
    margin-bottom: 16px;
  }
  .service-location {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 4px;
    margin-bottom: 16px;
    .location-icon {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 60px;
      svg {
        fill: $color-yellow;
      }
    }

    .location-text {
      color: $color-white;
      font-size: 16px;
      line-height: 18px;
      text-transform: capitalize;
    }
  }

  .price-line {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 4px 60px;

    .fuel-type {
      font-size: 18px;
      line-height: 40px;
    }

    .fuel-price {
      font-size: 28px;
      line-height: 40px;
      font-family: 'DS', sans-serif;
      background: linear-gradient(135deg, #272727 0%, $color-black 100%);
      border-radius: 4px;
      width: 64px;
      text-align: center;
      position: relative;
      margin-left: -18px;
      padding-right: 3px;

      span {
        position: absolute;
        top: -8px;
      }
    }
  }

  .spacer {
    flex: 1 1 100%;
  }

  .button {
    height: 62px;
    span {
      font-size: 20px;
    }
  }
}

.btn-back {
  color: $color-yellow;
  margin-bottom: 8px;
  position: relative;
  padding-left: 48px;
  span {
    position: absolute;
    left: 24px;
    top: -1px;
  }
  &:hover {
    cursor: pointer;
  }
}
