@use '../../../styles/variables' as *;

.user-info-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  position: relative;

  .user-avatar-bg {
    .img-container {
      background-color: $color-white-dark;
      color: $color-white;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .company {
    font-size: 12px;
    text-align: center;
    margin-top: 4px;
  }

  .name {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .version {
    position: absolute;
    left: -30px;
    top: 2px;
    color: #acacac;
  }

  .state {
    position: absolute;
    top: 78px;
    left: 0;
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    span {
      font-weight: 200;
    }
    &.row-state {
      color: #fff;
    }
  }

  .user-avatar {
    margin-bottom: 0;
    margin-right: 16px;
    margin-top: 6px;
  }
  .info-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $color-white;
    .info-data {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 320px;
      margin-bottom: 12px;

      .info-name {
        font-size: 16px;
        line-height: 1.13;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
      .info-tags {
        font-size: 12px;
        line-height: 1.25;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }
  }
  &.vertical {
    max-width: 100%;
    flex-direction: column;
    margin-bottom: 0;
    .user-avatar {
      margin-bottom: 12px;
      margin-right: 0px;
    }
    .info-container {
      .info-data {
        max-width: 100%;
      }
    }
  }
}

.info-row {
  .user-info-block {
    margin-bottom: 16px;
  }

  .state {
    color: #fff;

    &.row-state {
      color: #fff;
      font-weight: 500;
      margin-bottom: 16px;

      span {
        font-weight: 200;
      }
    }
  }
}

.page-header {
  .user-info-block {
    pointer-events: none;
    cursor: default;
  }
}

@media screen and (max-width: 576px) {
  .user-info-block.vertical {
    opacity: 1 !important;
  }
}
