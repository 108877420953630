@use '../../../styles/variables' as *;

.setting {
  margin-left: 8px;

  h2 {
    margin-top: 28px;
    margin-bottom: 10px;
  }

  .blackout {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 90;
    background: #000;
    border: 1px solid #707070;
    opacity: 0.3;
  }

  button {
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: $color-black !important;
    background: $color-yellow-light;
    display: inline-block;
    max-width: 107px;
    height: 40px;
    padding: 9px 14px 10px 14px;
    border-radius: 5px;
    border: unset;
    width: 100%;
    font-family: 'Maven', sans-serif;

    &:hover {
      cursor: pointer;
    }
  }

  button.active {
    background: $color-yellow !important;
  }

  .pop-up {
    position: fixed !important;
    padding: 20px 0 0 0;

    &_row-btn {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding-left: 40px;
      padding-right: 40px;
      margin-bottom: 21px;
      margin-top: 14px;
    }

    &_title {
      text-align: center;
      color: #00587f;
      font-size: 19px;
      margin-bottom: 18px;
      font-weight: 300;
      padding-left: 40px;
      padding-right: 40px;
    }

    &_row-btn {
      font-weight: 500;
    }
  }

  h1 {
    margin-bottom: 34px;
    margin-top: 46px;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: $color-white;
  }

  .setting-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 32px;
    margin-right: 16px;

    > label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 282px;
        width: 100%;
      }

      &:hover {
        cursor: pointer;
      }
    }

    p {
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: $color-white;
    }
  }
  .button-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -8px;
    box-sizing: border-box;
    width: calc(100% + 10px);
  }

  button.button {
    width: 100%;
    color: $color-white !important;
    max-width: 100%;
    display: flex;
    height: 60px;
    border-radius: 10px;
    font-size: 20px;
    margin-top: 60px;

    .mdi-icon {
      position: absolute;
      left: 24px;
    }
  }

  .select-box {
    margin-bottom: 40px;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $color-input-text;
    height: 48px;
    padding-top: 2px;

    .select__control {
      //height: 50px;
      //border-radius: 25px;
      outline: none;
      background-color: $color-input-background;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: $color-input-text;
      //padding-left: 10px;
      //padding-right: 10px;
      box-shadow: none;
      &:hover {
        cursor: pointer;
        z-index: 9;
      }

      svg {
        color: $color-yellow;
      }
    }

    &:hover {
      cursor: pointer !important;
    }
  }
}
