.bg-grey {
  background-color: #f1f1f1;
}

.text-xxl {
  font-size: 2.25rem;
}


.goBackBtn {
  outline: 0;
  border: 0;
  margin-top: 30px;
  cursor: pointer;
  border-radius: 8px;
  padding: 14px 24px 16px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  background: transparent;
  color: #5c5c5c;
  box-shadow: 0 0 0 3px #5c5c5c inset;
  :hover{
    transform: translateY(-2px);
  }

}
