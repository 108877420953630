.car-check-tabs {
  padding: 0px 30px;
  .nav {
    border-bottom: 1px solid #eee;
  }

  .nav li:hover {
    cursor: pointer;
  }

  .nav li.active {
    border-bottom: 2px solid #34495e;
    color: #34495e;
    font-weight: 500;
  }

  .tab-content {
    padding-top: 10px;
  }
}
