@use '../../../../styles/variables' as *;

.pictures-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;

  .damage-img {
    cursor: pointer;
    padding-right: 16px;
    object-fit: contain;
    width: 100%;
  }
}

.damage-guidance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 50px;
  margin-left: 30px;

  .damage-classification {
    color: $color-blue-middle;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    padding-bottom: 10px;
  }

  .damage-content {
    color: $color-blue-middle;
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
  }

  .images-container {
    justify-content: stretch;
    flex: 1;
    min-width: 180px;
    padding: 16px;
  }
}
