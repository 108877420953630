@use '../../styles/variables' as *;

.damages-container {
  .user-avatar {
    position: relative;
  }

  &.type-green {
    border-right: 5px solid $color-green;

    &:hover {
      background: rgba($color-green, 0.25);
    }
  }

  &.type-green_white {
    border-right: 5px solid $color-green;

    &:hover {
      background: rgba($color-green, 0.25);
    }
  }

  .green-white {
    right: -4px !important;
    svg {
      color: $color-green;
      border-radius: 50%;
      background: $color-white;
    }
  }

  &.type-green_tick {
    border-right: 5px solid $color-green;

    &:hover {
      background: rgba($color-green, 0.25);
    }
  }

  &.type-grey {
    border-right: 5px solid $color-white-dark;

    &:hover {
      background: rgba($color-white-dark, 0.25);
    }
  }

  &.type-red {
    border-right: 5px solid $color-red;

    &:hover {
      background: rgba($color-red, 0.25);
    }
  }

  &.type-red_archive {
    border-right: 5px solid $color-red;

    &:hover {
      background: rgba($color-red, 0.25);
    }
  }

  &.type-red-with-background {
    background: rgba($color-red, 0.25);
    border-right: 5px solid $color-red;

    &:hover {
      background: rgba($color-red, 0.5);
    }
  }

  &.type-blue {
    border-right: 5px solid $color-blue-middle;

    &:hover {
      background: rgba($color-blue-middle, 0.25);
    }
  }

  &.type-white {
    border-right: 5px solid $color-white-light;

    &:hover {
      background: rgba($color-white-light, 0.25);
    }
  }

  &.type-yellow {
    border-right: 5px solid $color-yellow;

    &:hover {
      background: rgba($color-yellow, 0.25);
    }
  }
}
