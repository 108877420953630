.appointment-card {
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 7px;
}

.appointment-card.appointment_status_green {
  border-right: 5px solid #95c11e;
}

.appointment-card.appointment_status_yellow {
  border-right: 5px solid #ffd500;
}

.appointment-card.appointment_status_red {
  border-right: 5px solid #e84d0e;
}

.appointment-card.appointment_status_gray {
  border-right: 5px solid #c9c9c9;
}

.appointment-card .appointment-action {
  background-color: #ffd500;
  border: none;
  outline: none;
  margin-left: 5px;
  padding: 2px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.appointment-card .appointment-due-date {
  border: 1px solid #edc542;
  padding: 5px 10px;
  border-radius: 5px;
}

.appointment-card .appointment-garage-details {
  padding: 5px 5px;
}

.appointment-card .appointment-due-date span.warning-icon {
  margin-right: 5px;
}

.appointment-card .appointments-dates {
  padding: 5px 5px;
}
