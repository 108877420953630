@use '../../../styles/variables' as *;

.viewer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50vh;
  padding-top: 20px;

  span {
    color: $color-blue-middle;
  }

  .ticket-image-container {
    display: flex;
    justify-content: space-evenly;
    flex: 1;

    img {
      max-height: 450px;
    }
  }
}
