.driver-select-wrapper {
  position: relative;
}

.driver-search .label {
  display: inline-block;
  width: 200px;
  color: #00587f;
  font-size: 16px;
  padding-bottom: 10px;
}

.select-driver-label {
  font-size: 16px;
  margin-bottom: 15px;
  margin-top: 15px;
}
