.bottom-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 14px 8px;
  padding: 6px 4px;
  border: 2px solid transparent;
  overflow: hidden;
  cursor: pointer;

  svg {
    flex: 0 0 28px;
    display: block;
    height: 28px;
    width: auto;
    fill: #FFD500; // color-yellow
    justify-content: center;
  }

  .label {
    flex: 1 1;
    white-space: nowrap;
    opacity: 0;
    width: 0;
    padding-right: 4px;
    color: #FFD500; // color-yellow
    font-size: 18px;
    text-align: center;
  }
}
