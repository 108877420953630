.personal-workplace{
  &_title{
    color: #00587F;
    font-size: 16px;
  }

  &_address{
    font-size: 16px !important;
    color: #000000;
    line-height: 18px;
    p{
      font-size: 16px;
    }
  }
}
