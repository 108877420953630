.edit-user-info {
  max-width: 500px;
  height: 100%;

  &.edit {
    display: flex;
    flex-direction: column;
  }

  &.driver {
    flex-direction: column !important;
  }
}
.error_field {
  .cmp-input .cmp-input-field {
    border: 1px solid red;
  }
}

.error-text {
  color: red;
}

.columns {
  display: flex;
  column-gap: 60px;
}

.profile-edit-license-form {
  .button {
    background: #95c11e !important;
    margin-top: 30px !important;
    display: flex;
    color: #fff;
  }

  .input {
    padding-top: 16px;
  }

  input {
    max-height: 20px;
  }
  .label {
    color: #00587f;
    padding-top: 16px;
    padding-bottom: 10px;
    font-size: 14px;
    margin-left: 10px;
  }

  .license-container {
    padding-bottom: 20px;
  }
  .flex {
    display: flex;
    justify-content: space-between;
  }

  .mt-10 {
    margin-top: 10px;
  }
  .license-img {
    width: 370px;
  }
  .flex {
    column-gap: 20px;
  }
}
.driver-license-form {
  display: flex;
  flex-direction: row;
  .column-pictures {
    margin-left: 100px;
  }
  .left-column {
    max-width: 1030px;
  }
}
.edit-license-container {
  height: 100%;
}
.save-button-text {
  color: #ffffff;
}
