@use '../../styles/variables' as *;

.datepicker-block {
  position: relative;
  transition: all 0.3s;
  height: 100%;
  background: #eef5f8;
  .rdp-caption_label {
    color: #00587f;
  }
  .datepicker-tongue {
    cursor: pointer;
  }
  .rdp {
    padding: 10px;
  }
  .rdp-button:focus {
    border-color: #00587f;
  }

  .rdp-day {
    position: relative;
  }
  .rdp-day.rdp-day_selected {
    background-color: #00587f;
    border-color: #00587f;
  }
  .rdp-day::before {
    transform: translateX(-50%);
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    height: 8px;
    content: '';
    width: 8px;
    left: 50%;
  }
  .rdp-day.pending::before {
    background: #ffd500;
  }

  .rdp-day.confirm::before {
    background: #95c11e !important;
  }
  .rdp-day.canceled::before {
    background: #e84d0e;
  }

  .rdp-day.default::before {
    background: #c9c9c9;
  }

  .rdp-head .rdp-head_row {
    color: #a9d3dc;
  }
}
