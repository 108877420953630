@use '../../../../styles/variables' as *;

.profile-settings {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  height: 100%;
  justify-content: space-between;

  .button-list {
    margin-right: 0;
    align-items: flex-end;

    .button {
      margin-right: 0;
      margin-bottom: 10px;
      height: 50px;
      opacity: 1;
      border-radius: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .close:after {
      display: none;
    }

    .close:before {
      display: none;
    }
  }

  .profile-settings-row {
    .settings-toggle {
      width: 100%;

      h1 {
        color: #000;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .settings-question {
      font-size: 17px;
      color: $color-marker-passenger-car;
      margin-top: 6px;
      display: flex;
      justify-content: flex-start;

      .driver-assign {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          margin-right: 25px;
          font-size: 17px;
        }
      }

      .settings-notification {
        margin-left: 40px;
      }
    }

    .settings-answer {
      align-content: center;
      justify-content: center;

      .toggler {
        margin-top: 0;
      }

      p {
        font-size: 17px;
        color: $color-marker-passenger-car;
        margin-left: 28px;
      }
    }

    .settings-info {
      background: #fffcd6;
      color: $color-yellow;
      border: 1px solid $color-yellow;
      border-radius: 8px;
      font-size: 18px;
      max-width: 310px;
      line-height: 20px;
      padding: 10px 14px 10px 2px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      svg {
        margin-top: 10px;
      }
    }

    .language-form {
      display: flex;
      flex-direction: column;

      .language-label {
        font-size: 17px;
        color: #00587f;
        margin-top: 10px;

        p {
          font-size: 17px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
