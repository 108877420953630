@use '../../../styles/variables' as *;

.edit-tag-container {
  position: absolute;
  left: 480px;
  top: 80px;
  z-index: 1;
  max-width: 400px;
  height: 700px;
  max-height: 70vh;
  min-height: 560px;
  background: white;
  margin-top: 20px;
  border-radius: 5px;
  padding: 10px 20px;

  .close-edit-tag-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: 0;
    color: $color-blue-middle;
    font-size: 18px;
    cursor: pointer;
  }

  .tag-select-container {
    position: relative;

    .tag-select-confirm {
      position: absolute;
      left: 270px;
      top: 35px;
      z-index: 10000;
      padding: 2px 3px;
      border-radius: 20px;

      &:hover {
        cursor: pointer;
        background-color: rgba(22, 22, 244, 0.05);
      }
    }
  }
  .tags-checklist-header {
    padding-top: 25px;
    color: $color-blue-middle;
    font-size: 15px;
    padding-left: 8px;
  }

  .tags-check-list {
    padding: 10px;
    height: 40vh;
    overflow-y: auto;
  }

  .input-label {
    color: $color-blue-middle;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .tags-select {
    padding-top: 5px !important;
  }

  .heading-tags {
    color: #00587f;
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 30px;
    text-align: center;
  }

  .save-tag-button {
    bottom: 20px;
    width: 100%;

    button {
      margin: 0;
      max-width: 400px;
    }

    .button-text {
      color: white;
      margin-left: -20px;
    }
  }
}
