@use '../../../styles/variables' as *;

.report-box-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title-box {
  background-color: $color-damage-red;
  color: $color-white;
  padding: 10px;
  width: 100%;
  max-width: 400px;
}
.message-box {
  background-color: $color-yellow-high-light;
  border: 1px solid $color-yellow-dark;
  border-radius: 10px;
  color: $color-yellow-dark;
  padding: 20px 40px;
  width: 100%;
  max-width: 400px;
  margin-top: 30px;
}
