@use '../../../styles/variables' as *;

.card {
  border: 0px solid transparent;
  border-right-width: 5px;
  border-radius: 7px;
  width: $card-width;
  box-shadow: 0 3px 6px 0 $color-shadow;
  overflow: hidden;
  min-height: 88px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  &.green-card {
    background-color: $color-green-light !important;
  }

  &.light-red-card {
    background-color: #eec8b9 !important;
  }

  &.light-blue-card {
    background-color: #f3faff !important;
  }

  .card-inner {
    flex-direction: column;
    padding: 9px 8px 8px;
    width: 100%;
    display: flex;

    .top-section {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      border: none;

      .left-section {
        display: flex;
        align-items: center;
        width: 58%;

        .text-container {
          margin-left: 15px;

          p {
            font-size: 16px;
            line-height: 1.13;
            margin: 0;
          }
        }
      }

      .right-section {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 42%;
        justify-content: center;
        text-align: right;

        // We have to put a small distance between middle items so they don't look collapsed
        > * {
          margin: 10px 0;

          &:first-child,
          &:last-child {
            margin: 0;
          }
          svg {
            width: 18px;
            height: auto;
            margin-left: 4px;
          }
        }
      }
    }

    .bottom-section {
      align-items: inherit;
    }
  }
}
