@use '../../../styles/variables' as *;

.employee {
  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  .user-avatar {
    margin-right: 0 !important;
  }

  .top-section {
    margin-top: 5px;
    justify-content: space-between;
  }

  .left-section {
    display: flex;
    flex-direction: row;

    & > .user-avatar {
      margin-bottom: 3px;
    }

    .employee-info {
      margin-left: 15px;
    }

    .employee-info p {
      font-size: 16px;
      line-height: 1.13;
      margin: 0;
    }

    p {
      margin-left: 16px;
      font-size: 16px;
      line-height: 18px;
    }
  }

  .right-section {
    margin-left: 14px;

    svg {
      margin-bottom: 2px !important;
      margin-top: 3px !important;
      width: 20px;
    }

    p {
      font-size: 14px !important;
      text-align: left;
    }

    .license-plate {
      margin-top: 6px !important;
    }
  }

  .left-section {
    max-width: 106px;
  }
}
