.rotate-icon {
  transform: rotate(180deg);
}

.center-v-h {
  display: flex;
  align-content: center;
  justify-content: center;
}

.customer-number_invalid {
  font-size: 13px;
  color: #f44337;
  padding: 20px 0px;
}
