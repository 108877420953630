@use '../../../styles/variables' as *;

.side-page {
  display: block;
  position: fixed;
  background: linear-gradient(to bottom, $color-blue-dark 0%, $color-blue-dark 25%, $color-blue-middle 100%);
  padding: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0;
  color: $color-white;
  transition: all $animation-duration ease-in-out;
  z-index: 99;

  &.open {
    width: 380px;
  }
  .close-button {
    display: flex;
    padding: 8px 0;

    &:hover {
      cursor: pointer;
    }
  }

  .side-page-content {
    padding: 12px;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    overflow-y: scroll;
  }

  .tongue {
    position: absolute;
    display: flex;
    align-items: center;
    height: 42px;
    width: 24px;
    background-color: $color-blue-dark-line;
    left: -24px;
    bottom: 120px;
    border-radius: 8px 0 0 6px;
    cursor: pointer;
    svg {
      fill: $color-yellow;
      transition-duration: $animation-duration;
      transition-property: transform;
    }
    &.open {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
