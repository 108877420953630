@use '../../../../styles/variables' as *;

.reservation-details-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 25px 40px;
}

.reservation-detailes-rows {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  // margin-top: 25px;
  max-width: 400px;

  .single-information-row {
    display: flex;
    font-size: 16px;
    padding: 5px 0;

    :first-child {
      width: 150px;
      min-width: 150px;
      max-width: 150px;
      display: block;
      font-size: 16px;
      color: $color-blue-middle;
      margin-right: 10px;
    }
    :last-child {
      font-size: 16px;
    }
  }
}

.reservation-billing-rows {
  .single-billing-row {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 5px 0;

    :first-child {
      font-size: 16px;
      color: $color-blue-middle;
      margin-right: 10px;
    }
    :last-child {
      font-size: 16px;
    }
    &.row-with-border {
      border-top: 1px solid $color-grey-dark;
    }
    &.balance {
      font-weight: 500;
      margin-top: 40px;
      > * {
        font-size: 18px !important;
      }
    }
  }
}
