@use '../../../../styles/variables' as *;

.main-form-container {
  background: $color-white-light;
  position: relative;
  width: 25vw;
  height: fit-content;
  padding: 10px 25px;
  margin-bottom: 100px;

  p.input-label {
    font-size: 15px;
  }

  .card-title {
    color: $color-blue-high;
    padding-left: 10px;
    font-size: 22px;
    font-weight: 500;
  }

  .damage-image-list {
    flex: 1;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
  }

  .damage-image-list .image-container {
    width: 110px;
    height: 70px;
    position: relative;
    margin: 10px;
    border: 1px solid gray;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .img-remove {
      cursor: pointer;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -5px;
      right: -5px;
      z-index: 5;
      border-radius: 50%;
      background-color: #e74c3c;
      padding: 2px;

      .mdi-icon {
        color: #fff;
      }
    }
  }

  .error-message {
    color: red;
    margin-top: -20px;
    margin-bottom: 20px;
  }
}

.dark-blue-input {
  background-color: $color-blue-white !important;
}

.blue-text-area {
  background-color: $color-blue-white !important;
  height: 200px;
}
