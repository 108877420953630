@use '../../styles/variables' as *;

@mixin tabs {
  background: $color-yellow;
  border-radius: 50%;
  color: $color-blue-dark;
  padding: 2px;
  width: 30px;
}

.vehicles-info {
  height: 100%;

  .input {
    margin-bottom: 20px;
    flex-grow: 0;
  }

  .icon-set {
    padding: 0 28px;
    margin-bottom: 20px;
    min-height: 36px;
    height: 36px;
    justify-content: space-between;

    svg {
      width: 28px;
      height: auto;
    }
  }

  .input input {
    padding: 0 16px;
    height: 36px;
    border-radius: 100px;
    background-color: hsla(0, 0%, 100%, 0.05);
    color: #fff;
    font-family: 'Maven', sans-serif !important;
  }

  .input input::-webkit-input-placeholder {
    color: $color-white;
    font-size: 18px;
    font-weight: 200;
  }

  .input input::-moz-placeholder {
    color: $color-white;
    font-size: 18px;
    font-weight: 200;
  }
  .input input:-ms-input-placeholder {
    color: $color-white;
    font-size: 18px;
    font-weight: 200;
  }
  .input input:-moz-placeholder {
    color: $color-white;
    font-size: 18px;
    font-weight: 200;
  }

  .input input:-moz-placeholder {
    color: $color-white;
    font-size: 18px;
  }

  .station-info {
    .input {
      display: none;
    }
  }

  .back {
    position: absolute;
    bottom: -29px;
    left: 19px;
    color: $color-yellow;
    margin: 10px 0px;
    z-index: 9;
    span {
      padding-right: 8px;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .back.none {
    display: none;
  }

  .right-section {
    h4 {
      font-weight: 100 !important;
    }

    .icon-set {
      padding: 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      max-width: 108px;
    }
  }

  h3 {
    text-align: center;
    margin-top: 30px;
    font-size: 18px;
  }

  .empty {
    text-align: center;
    margin-top: 30px;
    font-size: 18px;
    display: block;
    border-radius: 1px;
    margin-left: auto;
    margin-right: auto;
    max-width: 320px;
    background: $color-red;
    padding: 3px 4px 7px 4px;
  }

  .input input {
    padding: 0 42px 4px 47px;
  }

  .input-field {
    position: relative;
  }
  .input-field svg:nth-child(2) {
    position: absolute;
    right: 12px;
    top: 6px;
  }

  .input-field svg:nth-child(3) {
    position: absolute;
    left: 12px;
    top: 6px;
  }

  .input-field svg:nth-child(4) {
    position: absolute;
    right: 12px;
    top: 6px;
  }

  .license-plate {
    width: 102px;
  }

  .icon-left {
    font-size: 14px;
    svg {
      margin-bottom: -3px;
      width: 15px;
    }
  }

  .icon-right svg {
    color: red;
  }

  input::placeholder {
    color: $tread-inactive-color;
  }

  .icon-right svg {
    margin-bottom: -3px;
  }

  .card {
    width: calc(100% + 2px);
    margin-bottom: 10px;
    background: #00273c;
    &:hover {
      background-color: rgba(0, 39, 60, 0.6);
    }
  }

  .license-container {
    width: 82px;
    justify-content: center;
  }

  .mdi-icon:hover {
    cursor: pointer;
  }

  .count {
    position: absolute;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    font-weight: 200 !important;
    background: $color-damage-red;
    font-size: 11px;
    height: 28px;
    width: 28px;
    overflow: hidden;
    line-height: 28px;
    top: 14px;
    right: -21px;
    z-index: -1;
    padding-right: 2px;
  }

  .tab-block {
    position: relative;

    .service-icon {
      transform: scale(-1, 1);
      width: 24px;
      margin-top: 4px;
    }
  }

  .arrow {
    position: absolute;
    top: 17px;
    left: 43px;
    font-size: 11px;
  }

  .color-settings {
    color: $color-yellow;
  }

  .service-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }

  .none-field {
    display: inline-block;
    margin-top: 12px;
  }

  .tab-3 .service-info .input {
    display: none;
  }

  .tab-3 .service-icon {
    margin-top: 0;
  }

  &_zoom {
    text-align: center;
    margin-top: 30px;
    font-size: 18px;
    display: block;
    border-radius: 1px;
    margin-left: auto;
    margin-right: auto;
    max-width: 320px;
    background: #e84d0e;
    padding: 3px 4px 7px 4px;
    line-height: 20px;
  }

  .tab-1 > .icon-set,
  .tab-2 > .icon-set,
  .tab-3 > .icon-set,
  .tab-4 > .icon-set {
    position: relative;
  }
  .tab-1 > .icon-set div:nth-of-type(1) > .mdi-icon {
    @include tabs;
  }
  .tab-2 > .icon-set div:nth-of-type(2) > .mdi-icon {
    @include tabs;
  }
  .tab-3 > .icon-set div:nth-of-type(3) > .mdi-icon {
    @include tabs;
  }
  .tab-4 > .icon-set div:nth-of-type(4) > .mdi-icon {
    @include tabs;
  }

  .tab-1,
  .tab-2,
  .tab-3,
  .tab-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    height: calc(100% - 110px);
  }
}
