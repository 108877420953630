.logo-wrapper {
  align-items: center;
  position: absolute;
  height: 65%;
  left: 50%;
  bottom: 0px;
  width: 70%;
}

.logo-wrapper > img {
  width: 100%;
  height: 100%;
}


@media only screen and (max-width: 700px) {
  .rectangle {
    display: none;
  }

  .login-container {
    flex-direction: column;
  }

  .left-block {
    padding-bottom: 100px;
  }

  .right-block {
    padding-bottom: 100px;
  }

  .logo-wrapper {
    top: 20%;
  }
  .login-container .right-block .privacy-policy {
    overflow: unset;
  }

}
