/*
 * Breakpoints
 */
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;

/*
  Colors
*/

$color-white: #fff;
$color-white-light: #EEF5F8;
$color-white-grey: #CBCBCB;
$color-white-dark: #c9c9c9;
$color-blue-white: #bad4dc;
$color-blue-light: #2D7695;
$color-blue-middle: #00587f;
$color-blue-high: #004B6E;
$color-blue-black: #002d43;
$color-blue-dark: #00273C;
$color-blue-dark-line: #004c6f;
$color-blue-dark-gradient: #004e71;
$color-yellow: #FFD500;
$color-yellow-dark: #E8B50E;
$color-yellow-dark-light: #ffdb4a;
$color-yellow-light: #ffea7f;
$color-yellow-high-light: #fffcd6;
$color-black: #000;
$color-black-gray: #131212;
$color-black-light: #333;
$color-red: #E84D0E;
$color-green: #95C11E;
$color-green-light: #d1e996;

$color-shadow: rgba(0, 0, 0, 0.16);
$color-grey-dark: #777777;
$color-grey-black: #707070;
$color-grey-light: #6A6969;
$color-grey-extra-light: #B6B6B6;
$color-grey-extra: #ccc;

$color-price-low: #54b439;
$color-price-middle: #ff9207;
$color-price-high: #de6364;

$color-marker-transporter: #00273C;
$color-marker-passenger-car: #00587F;
$color-marker-cluster: #2d7695;
$color-marker-service: #078c6c;

$color-input-text: #00273c;
$color-input-background: #eef5f8;

$color-damage-green: #95c21e;
$color-damage-yellow: #fce501;
$color-damage-red: #f44337;

$tread-inactive-color: #c8c8c8;

/*
  Sizes
*/

$navbar-small-size: 56px;
$navbar-big-size: 200px;
$card-width: 357px;

/*
  Animations
*/

$animation-duration: 0.3s;
