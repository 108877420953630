.back-row {
  background-color: #004c6f;
  display: flex;
  align-items: center;
}

.arrow-back {
  display: flex;
  cursor: pointer;
  padding: 5px 10px;
}

.back-text {
  color: #fff;
  padding-left: 5px;
  font-weight: 700;
}

.border_grey {
  border-right: 5px solid #c9c9c9;
}

.border_red {
  border-right: 5px solid #e84d0e;
}

.border_green {
  border-right: 5px solid #95c11e;
}

.border_yellow {
  border-right: 5px solid #ffd500;
}
