@use '../../../styles/variables' as *;

.settings {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;

  .pop-up_title {
    text-align: center;
    color: #00587f;
    font-size: 19px;
    margin-bottom: 18px;
    font-weight: 300;
    padding-left: 40px;
    padding-right: 40px;
  }

  .button {
    color: #fff;
    margin-right: 0;
  }

  .is-success {
    &:hover {
      opacity: 0.8;
    }

    .button-text {
      font-size: 20px !important;
      color: $color-white !important;
      font-weight: 500;
    }
  }
}

.settings-row {
  display: flex;
  margin-top: 10px;
  max-width: 812px;
  flex-direction: row;
  justify-content: space-between;

  .settings-answer {
    display: flex;
  }

  .settings-toggle {
    max-width: 350px;
    width: 100%;

    h1 {
      color: #000;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .settings-question {
    font-size: 17px;
    color: $color-marker-passenger-car;
    margin-top: 6px;
  }

  .settings-answer {
    p {
      font-size: 17px;
      color: $color-marker-passenger-car;
      margin-left: 28px;
    }
  }

  .settings-info {
    background: #fffcd6;
    color: $color-yellow;
    border: 1px solid $color-yellow;
    border-radius: 8px;
    font-size: 18px;
    max-width: 310px;
    line-height: 20px;
    padding: 10px 14px 10px 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    svg {
      margin-top: 15px;
    }

    div {
      padding-left: 4px;
      padding-bottom: 16px;
    }
  }

  label.toggler {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 6px;
    font-size: 12px;
    color: $color-black;
    margin-top: 10px;

    input {
      max-width: 85px;
      padding: 3px 3px;
      font-size: 10px !important;
    }

    p {
      width: 100%;
      font-size: 12px;
    }

    .react-toggle-track {
      width: 26px;
      height: 10px;
    }

    .toggle {
      margin-top: 6px;
    }

    .react-toggle-thumb {
      width: 14px;
      height: 14px;
      top: -2px;
      left: -1px;
    }

    .react-toggle--checked .react-toggle-thumb {
      left: 14px;
      border-color: $color-yellow;
      background-color: $color-yellow;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.41);
    }

    .react-toggle--checked .react-toggle-track {
      background-color: $color-yellow;
    }

    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
      border-color: $color-yellow;
    }

    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
      background-color: $color-yellow !important;
    }

    .react-toggle--checked .react-toggle-thumb {
      left: 16px;
      border-color: $color-yellow;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
