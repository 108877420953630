.appointment-cancel-modal {
  background-color: rgba(0, 0, 0, 0.75);
}

.cancel-appointment-form {
  background-color: #fff;
  max-width: 500px;
  border-radius: 10px;
  padding: 20px 25px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.cancel-appointment-form .title {
  color: #00587f;
  font-size: 16px;
  font-weight: 300;
}

.cancel-appointment-form .appointment-options {
  width: 100%;
  margin: 10px 0px;
}

.appointment-cancel-modal .error-message {
  color: red;
  padding-bottom: 5px;
}
