.input-list {
  display: flex;

  .button {
    margin-top: 8px;
    margin-right: 4px;
  }

  &.date {
    justify-content: space-between;
  }
}
