.map {
  width: 100%;
  height: 100%;

  .map-row {
    height: 100%;
  }

  .car-icon {
    background: #fff !important;
    width: 25px;
    height: 25px;
    z-index: 4 !important;
  }

  .car-icon > img {
    max-width: 26px !important;
    width: 26px !important;
    z-index: 4 !important;
    position: relative;
  }
  .license-plate-icon {
    position: absolute;
    height: 22px;
    top: 0px;
    background: #fff;
    left: 21px;
    z-index: 3 !important;
    width: max-content;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #00587f;
    padding: 0 3px 0 8px;
    max-width: 110px !important;
    line-height: 22px;
    border-radius: 4px;
  }
}
