.tags-select {
  padding-top: 40px;
}

.tags-select .search-input-field {
  background-color: #ccdde5;
  border: 1px solid #ccdde5;
}

.tags-select .inner-dd-list {
  background-color: #ccdde5;
}

.tags-select .list-items p {
  font-size: 16px;
}
.checkmark {
  display: inline-block;
  position: absolute;
  right: 35px;
  top: 50%;
  cursor: pointer;
  color: #91c11e;
  transform: translateY(-50%);
}
